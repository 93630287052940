export const policyTableData = [
  {
    policyName: "Identity Policy_1 Usergroups_AzureIdp_Oct_2024",
    type: "identity",
    config: {
      SemanticGuardrailConfig: {
        entities: ["Finance", "Secrets"],
        topics: ["Harmful"],
      },
      Exceptions: false,
      Groups: [
        "us_support_group@acmecorp.com",
        "underwriter_group@acmecorp.com",
      ],
      ConfidentialityTags: "C5 to C3",
    },
    addedOn: "2024-10-10T11:17:03.384Z",
    owner: "John Doe",
    violations: 10,
  },
];

export const topicsTableData = [
  {
    topic: "Medical Advice",
    description: "Providing medical advice or information on ..",
    createdOn: "2023-10-21  05:12:34",
  },
  {
    topic: "Financial Reports",
    description: "Reports or information related to financ ..",
    createdOn: "2023-10-24 15:32:18",
  },
];

export const entityTableData = [
  {
    entity: "Employee ID",
    createdOn: "2023-10-22  12:11:48",
  },
  {
    entity: "Company Confidential",
    createdOn: "2023-10-23  04:32:32",
  },
];
