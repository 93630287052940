import { useTheme } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/appContext";
import Box from "../common/Box";
import Checkbox from "../common/Checkbox";
import Divider from "../common/Divider";
import NoData from "../common/NoData";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import TextButton from "../common/TextButton";
import Typography from "../common/Typography";
import JiraIcon from "../../assets/img/jira.svg";
import SlackIcon from "../../assets/img/slack.svg";
import MoreVert from "@mui/icons-material/MoreVert";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import NodeChain from "../common/NodeChain";
import Chip from "../common/Chip";
import DatastoreTicketModal from "../modal/datastore-ticket-modal/DatastoreTicketModal";
import { TextField } from "../common/TextField";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import FlagIcon from "@mui/icons-material/Flag";
import Tooltip from "../common/Tooltip";
import { TOASTER_TYPES, Toaster } from "../../utils/toaster";
import SuggestLabelDialog from "./LLMModel/SuggestLabelDialog";
import DiscardLabelDialog from "./DiscardLabelDialog";
import ViewMoreBlock from "../common/ViewMoreBlock";
import StarIcon from "../../assets/img/star.svg";
import MultipleContextDetailsDialog from "./LLMFramework/MultipleContextDetailsDialog";
import { getFileName, getStringFromArray } from "../../utils/commonUtils";
import {
  fileDetails,
  retrievalUserDetails,
} from "../../mock-data/retrievalUserDetails";

const NUM_OF_DEFAULT_CHARACTERS = 250;

const MessageDetailBlock = ({ data, index }) => {
  const theme = useTheme();
  const styles = {
    messageContainer: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.25),
    },
    messageCard: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    messageFontSize: {
      fontSize: "13px",
    },
    dateText: {
      display: "flex",
      gap: theme.spacing(0.5),
    },
    messageData: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    keyValue: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.75),
    },
    tableCellAction: {
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      color: theme.palette.primaryBlue.main,
      cursor: "pointer",
      "&.Mui-disabled": {
        color: theme.palette.surface20.main,
        pointerEvents: "unset",
        cursor: "not-allowed",
      },
    },
    originChain: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2.25),
      alignItems: "start",
    },
    policyChip: {
      display: "flex",
      width: "min-content",
      backgroundColor: "rgba(255, 101, 91, 0.20)",
      borderRadius: theme.spacing(0.5),
    },
    finalContext: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    aiChip: {
      color: theme.palette.surface60.main,
      backgroundColor: "transparent",
      "& .MuiChip-avatar": {
        width: "10px",
        height: "10px",
      },
    },
    divider: {
      height: "13px",
    },
    initalContext: {
      display: "flex",
      gap: theme.spacing(1),
    },
  };
  const [showOrigin, setShowOrigin] = useState(false);
  const [openSnippetsDialog, setOpenSnippetsDialog] = useState(false);
  const [snippetsData, setSnippetsData] = useState(null);
  const dateString = moment(data.retrievedOn).format("YYYY-MM-DD");
  const timeString = moment(data.retrievedOn).format("HH:mm:ss");

  const isMultipleSnippet =
    typeof data.context !== "string" && data.context?.length >= 1;

  const onCloseSnippetDialog = () => {
    setSnippetsData(null);
    setOpenSnippetsDialog(false);
  };

  return (
    <Box sx={styles.messageContainer}>
      <Box sx={styles.messageCard}>
        <Box sx={styles.messageData}>
          <Box sx={styles.keyValue}>
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Prompt
            </Typography>

            <ViewMoreBlock
              value={data.prompt}
              length={NUM_OF_DEFAULT_CHARACTERS}
            />
          </Box>
          {data?.promptUserString && (
            <Box sx={styles.keyValue}>
              <Typography
                variant="caption"
                color={theme.palette.surface40.main}
              >
                User
              </Typography>

              <ViewMoreBlock
                value={data.promptUserString}
                length={NUM_OF_DEFAULT_CHARACTERS}
              />
            </Box>
          )}
          <Box sx={styles.keyValue}>
            <Box sx={styles.initalContext}>
              <Typography
                variant="caption"
                color={theme.palette.surface40.main}
              >
                Initial Context
              </Typography>
              {isMultipleSnippet && (
                <>
                  <Divider orientation="vertical" sx={styles.divider} />
                  <TextButton
                    sx={{ fontSize: "12px" }}
                    onClick={() => {
                      setSnippetsData(data);
                      setOpenSnippetsDialog(true);
                    }}
                  >
                    Show All {data?.context?.length}
                  </TextButton>
                </>
              )}
            </Box>
            <ViewMoreBlock
              value={
                isMultipleSnippet ? data.context[0]?.snippet : data.context
              }
              length={NUM_OF_DEFAULT_CHARACTERS}
            />
          </Box>
          <Box sx={styles.keyValue}>
            <Box sx={styles.finalContext}>
              <Typography
                variant="caption"
                color={theme.palette.surface40.main}
              >
                Final Context
              </Typography>
              {!data?.notAIAssisted && (
                <>
                  <Divider orientation="vertical" sx={styles.divider} />
                  <Chip
                    sx={styles.aiChip}
                    label="AI Assisted"
                    avatar={<img src={StarIcon} />}
                  />
                </>
              )}
            </Box>
            <ViewMoreBlock
              value={data.finalContext}
              length={NUM_OF_DEFAULT_CHARACTERS}
            />
          </Box>
          <Box sx={styles.keyValue}>
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Response
            </Typography>

            <ViewMoreBlock
              value={data.response}
              length={NUM_OF_DEFAULT_CHARACTERS}
            />
          </Box>
          {data.policiesViolated && data.policiesViolated.length > 0 && (
            <Box sx={styles.keyValue}>
              <Typography
                variant="caption"
                color={theme.palette.surface40.main}
              >
                Policies Violated
              </Typography>
              <Typography
                variant="caption"
                color={theme.palette.surface70.main}
              ></Typography>
              <Chip
                sx={styles.policyChip}
                label={
                  <Typography
                    variant="caption"
                    color={theme.palette.surface70.main}
                  >
                    {data.policiesViolated.join(" ")}
                  </Typography>
                }
              />
            </Box>
          )}

          <Box sx={styles.dateText}>
            <Typography variant="caption" color={theme.palette.surface40.main}>
              Retrieved On:
            </Typography>
            <Typography variant="caption" color={theme.palette.surface40.main}>
              {dateString}
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography variant="caption" color={theme.palette.surface40.main}>
              {timeString}
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography variant="caption" color={theme.palette.surface40.main}>
              By {data.retrievedBy}
            </Typography>
            {/*<Divider orientation="vertical" flexItem />
            <Typography variant="caption" color={theme.palette.surface40.main}>
              {data.region}
            </Typography>*/}
          </Box>
          {data?.nodesChain?.length > 0 && (
            <Box sx={styles.originChain}>
              <TextButton
                onClick={() => setShowOrigin((prevState) => !prevState)}
                startIcon={
                  showOrigin ? (
                    <ExpandLessRoundedIcon />
                  ) : (
                    <ExpandMoreRoundedIcon />
                  )
                }
                sx={styles.tableCellAction}
              >
                {`${showOrigin ? "Hide" : "Show"}`} Origin
              </TextButton>
              {showOrigin && (
                <NodeChain
                  nodes={data?.nodesChain}
                  linkColor={theme.palette.surface40.main}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
      {openSnippetsDialog && (
        <MultipleContextDetailsDialog
          open={openSnippetsDialog}
          onClose={onCloseSnippetDialog}
          data={snippetsData}
        />
      )}
    </Box>
  );
};

const MessageDetails = ({
  messagesList = [],
  frameworkData = {},
  selectedSensitiveLabel = "",
  sensitiveData,
  selectedUser = "",
  selectedFilter,
}: {
  frameworkData: any;
  messagesList: any;
  selectedSensitiveLabel: string;
  sensitiveData: any;
  selectedUser: string;
  selectedFilter?: string;
}) => {
  const [ticketModal, setTicketModal] = useState(false);
  const [globalTicketModal, setGlobalTicketModal] = useState(false);
  const [ticketTitle, setTicketTitle] = useState("");
  const [ticketDescription, setTicketDescription] = useState({});
  const [sensitiveLabelCount, setSensitiveLabelCount] = useState(0);
  const [openSuggestLabelDialog, setOpenSuggestLabelDialog] = useState(null);
  const [isDiscardLabelDialog, setIsDiscardLabelDialog] = useState(null);
  const [messageList, setMessageList] = useState<any>([]);
  const [addedFeedback, setAddedFeedback] = useState<
    Array<{
      prompt: string;
      inAccurateLabel: string;
    }>
  >([]);
  const theme = useTheme();

  const labels = [
    "API Key",
    "JWT Token",
    "Client Access Token",
    "Client Secret",
    "Symmetric Key",
    "MRN",
    "PII - SSN",
    "PII - CC",
  ];

  const styles = {
    messagePanel: {
      borderRadius: theme.spacing(0.5),
      height: "400px",
      overflow: "hidden",
    },
    checkbox: {
      "& .MuiSvgIcon-root": { fontSize: "16px" },
    },
    tableHeader: {
      "& .MuiTableCell-head": {
        color: theme.palette.surface50.main,
        fontSize: "13px",
        lineHeight: "1.125rem",
        background: theme.palette.surface0.main,
        borderBottom: `1px solid ${theme.palette.surface20.main} !important`,
        paddingY: theme.spacing(1.25),
        zIndex: 10,
      },
    },
    checkboxCell: {
      verticalAlign: "top",
      "&.MuiTableCell-root ": {
        borderBottom: `1px solid ${theme.palette.surface20.main}`,
      },
    },
    tableCellAction: {
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center",
      fontSize: "13px",
      color: theme.palette.primaryBlue.main,
      cursor: "pointer",
      "&.Mui-disabled": {
        color: theme.palette.surface20.main,
        pointerEvents: "unset",
        cursor: "not-allowed",
      },
    },
    bulkActions: {
      display: "flex",
      gap: theme.spacing(1),
    },
    actionImg: {
      height: "16px",
    },
    actionsCell: {
      display: "flex",
      gap: theme.spacing(1),
      justifyContent: "space-between",
      alignItems: "center",
    },
    dividerHeight: { height: "13px" },
    thumbsIcon: {
      color: theme.palette.surface30.main,
      height: "20px",
      width: "20px",
    },
    tableHeight: {
      height: "100%",
      "&.MuiTableCell-root ": {
        borderBottom: `1px solid ${theme.palette.surface20.main}`,
      },
    },
    thumbsIconBox: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
    },
    modalBody: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    modalContent: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
  };
  useEffect(() => {
    if (selectedSensitiveLabel) {
      const count = sensitiveData?.find(
        (item) => item?.sensitiveLabel === selectedSensitiveLabel
      )?.messages?.length;
      setSensitiveLabelCount(count);
    }
  }, [selectedSensitiveLabel]);

  const buildModalData = (item, appName = "") => {
    const groups = retrievalUserDetails.find(
      (user) => user.name === item?.retrievedBy
    )?.groups;

    const fileName = getFileName(item?.sourceFile);
    const fileObj = fileDetails.find((file) =>
      file.fileName.includes(fileName)
    );
    const fileGroups = fileObj?.authorizedGroups;
    const fileAccessCount = fileObj?.accessCount;

    if (globalTicketModal) {
      return `${
        frameworkData?.frameworkSummary?.name
      }\n\nFramework details:\nName: ${
        frameworkData?.frameworkSummary?.family
      }\nVersion: ${
        frameworkData?.frameworkSummary?.version
      }\nVectorDB Location: ${
        frameworkData?.frameworkSummary?.location
      }\nEmbedding Model: ${
        frameworkData?.frameworkSummary?.embeddingModel
      }\n\nInstance details:\nAccount ID: ${
        frameworkData?.providerInfo?.aws?.accountId
      }\nInstance ID: ${
        frameworkData?.providerInfo?.aws?.instanceId
      }\nLabels: -\nRuntime: ${
        frameworkData?.instanceDetails?.runtime
      }\nHost: ${frameworkData?.instanceDetails?.host}\nPath: ${
        frameworkData?.instanceDetails?.path
      }\nIP: ${frameworkData?.instanceDetails?.ip}\nLanguage: ${
        frameworkData?.instanceDetails?.language
      }\n\nNumber of sensitive labels: ${sensitiveLabelCount}\n\nPrompt: \n${
        item?.prompt
      }\n\nContext: \n${item?.context}\n\nResponse: \n${
        item?.context
      }\n\nPolicies Violated: \n${item?.policiesViolated.join(" ")}`;
    }
    return `Document Name: ${fileName}\nDocument Authorized Groups: ${
      fileGroups && fileGroups?.length > 0
        ? getStringFromArray(fileGroups, fileGroups?.length)
        : "-"
    } \n\nAccess by User: ${item?.retrievedBy}\nAuthorized Groups for User: ${
      groups && groups?.length > 0
        ? getStringFromArray([...groups], groups?.length)
        : "-"
    }\n\nPrompt: \n${item?.prompt}\n\nContext: \n${
      item?.context && item?.context?.length > 0
        ? item?.context[0]?.snippet
        : item?.context || "-"
    }\n\nResponse: \n${
      item?.response
    }\n\nPolicies Violated: \n${item?.policiesViolated.join(
      " "
    )} - ${selectedSensitiveLabel}`;
  };

  useEffect(() => {
    setAddedFeedback(
      JSON.parse(localStorage.getItem("DATA_SOURCE_FEEDBACK") || "[]")
    );
  }, [localStorage.getItem("DATA_SOURCE_FEEDBACK")]);

  useEffect(() => {
    if (selectedUser) {
      let filteredMessages = messagesList;
      if (selectedFilter === "user") {
        filteredMessages = messagesList?.filter(
          (msg) => msg?.retrievedBy?.toString() == selectedUser?.toString()
        );
      } else {
        const fileName = selectedUser?.split("/")?.at(-1);
        filteredMessages = messagesList?.filter((msg) =>
          msg?.sourceFile?.includes(fileName)
        );
      }
      setMessageList([...filteredMessages]);
    } else setMessageList(messagesList);
  }, [messagesList, selectedUser]);

  const handleAddFeedback = (val: string) => {
    const preAddedValues = JSON.parse(
      localStorage.getItem("DATA_SOURCE_FEEDBACK") || "[]"
    );
    let isPromptExist;
    if (preAddedValues?.length) {
      isPromptExist = preAddedValues?.filter(
        (item) => item?.prompt !== openSuggestLabelDialog
      );
      isPromptExist = [
        ...isPromptExist,
        {
          prompt: openSuggestLabelDialog,
          inAccurateLabel: val,
        },
      ];
    } else {
      isPromptExist = [
        {
          prompt: openSuggestLabelDialog,
          inAccurateLabel: val,
        },
      ];
    }
    localStorage.setItem("DATA_SOURCE_FEEDBACK", JSON.stringify(isPromptExist));
    handleCloseFeedbackDialog();
    Toaster(
      TOASTER_TYPES.SUCCESS,
      `You have successfully ${
        preAddedValues?.length === isPromptExist?.length ? "updated" : "added"
      } label!!`
    );
  };

  const handleAddFeedbackDialog = (isReported, prompt) =>
    isReported
      ? setIsDiscardLabelDialog(prompt)
      : setOpenSuggestLabelDialog(prompt);

  const handleCloseFeedbackDialog = () => setOpenSuggestLabelDialog(null);

  const getIsReported = (prompt) => {
    if (addedFeedback?.length) {
      const isPromptExist = addedFeedback?.find(
        (item) => item?.prompt === prompt
      );
      if (isPromptExist) {
        return `You Suggested new label as ‘${isPromptExist?.inAccurateLabel}’`;
      }
    }
    return false;
  };

  const handleDiscardFeedback = () => {
    const preAddedValues = JSON.parse(
      localStorage.getItem("DATA_SOURCE_FEEDBACK") || "[]"
    );
    const isPromptExist = preAddedValues?.filter(
      (item) => item?.prompt !== isDiscardLabelDialog
    );
    localStorage.setItem("DATA_SOURCE_FEEDBACK", JSON.stringify(isPromptExist));
    handleCloseDiscardFeedback();
    Toaster(TOASTER_TYPES.SUCCESS, "You have successfully discarded label!!");
  };

  const handleEditFeedback = () => {
    setOpenSuggestLabelDialog(isDiscardLabelDialog);
    handleCloseDiscardFeedback();
  };

  const handleCloseDiscardFeedback = () => setIsDiscardLabelDialog(null);

  return (
    <Box sx={styles.messagePanel}>
      <TableContainer
        sx={{
          height: "400px",
        }}
      >
        <Table sx={styles.tableHeight}>
          <TableHead sx={styles.tableHeader}>
            <TableRow>
              <TableCell>
                <Checkbox label="selectAll" sx={styles.checkbox} />
              </TableCell>
              <TableCell>
                <Box sx={styles.bulkActions}>
                  <Divider orientation="vertical" flexItem />
                  <TextButton
                    onClick={(event) => {
                      event.stopPropagation();
                      event.stopPropagation();
                      setGlobalTicketModal(true);
                      setTicketTitle(selectedSensitiveLabel);
                      setTicketDescription({
                        ...frameworkData,
                        ...messageList[0],
                      });
                      setTicketModal(true);
                    }}
                    startIcon={
                      <img
                        src={JiraIcon}
                        alt="slack"
                        style={styles.actionImg}
                      />
                    }
                    sx={styles.tableCellAction}
                    disabled={true}
                  >
                    Open Jira Ticket
                  </TextButton>
                  <Divider orientation="vertical" flexItem />
                  <TextButton
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    startIcon={
                      <img
                        src={SlackIcon}
                        alt="slack"
                        style={styles.actionImg}
                      />
                    }
                    sx={styles.tableCellAction}
                    disabled={true}
                  >
                    Notify on Slack
                  </TextButton>
                  <Divider orientation="vertical" flexItem />
                  <TextButton>
                    <MoreVert fontSize="small" />
                  </TextButton>
                </Box>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messageList?.length > 0 ? (
              messageList?.map((message, index) => {
                const tooltipMessage =
                  getIsReported(message?.prompt) ||
                  "Report As Inaccurate Label";
                const isReported = getIsReported(message?.prompt);
                return (
                  <TableRow>
                    <TableCell sx={styles.checkboxCell}>
                      <Checkbox label={message?.msgText} sx={styles.checkbox} />
                    </TableCell>
                    <TableCell
                      sx={{
                        "&.MuiTableCell-root ": {
                          borderBottom: `1px solid ${theme.palette.surface20.main}`,
                        },
                      }}
                    >
                      <MessageDetailBlock data={message} index={index} />
                    </TableCell>
                    <TableCell sx={styles.tableHeight}>
                      <Box sx={styles.actionsCell}>
                        <TextButton
                          onClick={(event) => {
                            event.stopPropagation();
                            setTicketTitle(message?.prompt);
                            setTicketDescription(message);
                            setTicketModal(true);
                          }}
                          startIcon={<img src={JiraIcon} alt="slack" />}
                          sx={styles.tableCellAction}
                        >
                          Open Ticket
                        </TextButton>
                        <Divider
                          orientation="vertical"
                          sx={styles.dividerHeight}
                        />
                        <Box sx={styles.thumbsIconBox}>
                          <Tooltip title={tooltipMessage}>
                            <div>
                              <TextButton
                                onClick={() =>
                                  handleAddFeedbackDialog(
                                    isReported,
                                    message?.prompt
                                  )
                                }
                                startIcon={
                                  isReported ? (
                                    <FlagIcon />
                                  ) : (
                                    <FlagOutlinedIcon />
                                  )
                                }
                              >
                                {isReported ? "Reported" : "Report"}
                              </TextButton>
                            </div>
                          </Tooltip>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <NoData
                      customText={
                        selectedUser
                          ? `No Messages Found for ${selectedUser}`
                          : "No Messages Found"
                      }
                    />
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {ticketModal && (
        <DatastoreTicketModal
          handleModal={() => {
            setTicketModal(false);
            setGlobalTicketModal(false);
          }}
        >
          <Box sx={styles.modalBody}>
            <Box sx={styles.modalContent}>
              <Typography
                variant="caption"
                color={theme.palette.surface60.main}
              >
                Ticket Name: <span className="mandatory-icon">*</span>
              </Typography>
              <TextField fullWidth value={ticketTitle} />
            </Box>
            <Box sx={styles.modalContent}>
              <Typography
                variant="caption"
                color={theme.palette.surface60.main}
              >
                Description:
              </Typography>
              <TextField
                fullWidth
                multiline
                minRows={10}
                rows={10}
                value={buildModalData(
                  ticketDescription,
                  frameworkData?.frameworkSummary?.name
                )}
              />
            </Box>
          </Box>
        </DatastoreTicketModal>
      )}
      <SuggestLabelDialog
        open={openSuggestLabelDialog}
        labels={labels}
        onClose={handleCloseFeedbackDialog}
        onSubmit={handleAddFeedback}
        value={
          addedFeedback?.find((item) => item?.prompt === openSuggestLabelDialog)
            ?.inAccurateLabel || ""
        }
      />
      <DiscardLabelDialog
        open={isDiscardLabelDialog}
        onClose={handleCloseDiscardFeedback}
        onEdit={handleEditFeedback}
        onDiscard={handleDiscardFeedback}
      />
    </Box>
  );
};

export default MessageDetails;
