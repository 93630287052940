import moment from "moment";

export const frameworkPromptSummaryData = [
  {
    name: "SupportApp-1",
    description: "Support productivity co-pilot for level-1 and level-2 teams",
    loaderData: [
      {
        value: 2,
        label: "SharePoint Loader",
        outOf: 12,
        source: "https://acme-corp.sharepoint.com/support-portal/documents/",
        sourceType: "Directory",
        updatedAt: "2024-09-30 04:42:19",
        message: "Files with Restricted Data",
      },
      {
        value: 1,
        label: "Slack API Loader",
        outOf: 2,
        source: "org: acme-corp.slack.com, channels: #support",
        sourceType: "Channels",
        message: "Channels with Restricted Data",
        updatedAt: "2024-09-30 14:10:03",
      },
    ],
    policyViolations: [
      {
        count: 36,
        datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
        description:
          "LLM accessible from assets with security issues that could facilitate malicious activity.",
        lastViolatedAt:
          moment().subtract(1, "month").format("DD MMM YYYY").toString() +
          ", 06:22:40",
        policyViolationId: "6b0b274b-c780-4d78-9391-5ae1e1827fb5",
        policyViolationName: "Restricted topics in prompt session",
        remediation:
          "Resolve security issues on assets facilitating malicious activity.",
        ticket_exist: false,
        tickets: 0,
        url: null,
      },
    ],
    promptSummaryData: [
      [
        {
          sensitiveLabel: "Product Roadmap",
          labelType: "topic",
          messages: [
            //            {
            //              prompt:
            //                "Describe the best practice in configuring the Acme1000 product.",
            //              context: [
            //                {
            //                  snippet:
            //                    "The Acme1000 is a high-performance, scalable network storage solution designed for enterprise environments. It offers robust data management capabilities, including seamless integration with cloud services, automated backups, and advanced data deduplication. When configuring the Acme1000, it is important to ensure that RAID configurations are properly set up to maintain data redundancy and fault tolerance. Users should also leverage automated backup features to regularly archive critical data. Acme1000 supports a variety of network protocols, so aligning the protocol configuration with your infrastructure is key for optimal performance. “Also, don’t forget to enable deduplication—it saves a lot of storage space over time, especially if you’re working with repetitive data sets.",
            //                  retrievedFrom:
            //                    "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
            //                  groups: ["us_support_group@acmecorp.com"],
            //                },
            //                {
            //                  snippet:
            //                    "We’ve found that the most common issue during the Acme1000 setup is improper network protocol selection. By default, it supports NFS, SMB, and iSCSI. Depending on your workload (e.g., large-scale file storage vs. transactional data), selecting the appropriate protocol is crucial. One of our customers had major latency issues because they initially chose SMB for a file server environment that heavily relied on NFS. Always double-check protocol compatibility with your use case.",
            //                  retrievedFrom: "org: acme-corp.slack.com, channels: #support",
            //                  groups: ["us_support_group@acmecorp.com"],
            //                },
            //              ],
            //              finalContext:
            //                "The Acme1000 is a high-performance, scalable network storage solution designed for enterprise environments. It offers robust data management capabilities, including seamless integration with cloud services, automated backups, and advanced data deduplication. When configuring the Acme1000, it is important to ensure that RAID configurations are properly set up to maintain data redundancy and fault tolerance. Users should also leverage automated backup features to regularly archive critical data. Acme1000 supports a variety of network protocols, so aligning the protocol configuration with your infrastructure is key for optimal performance.",
            //              response: `
            //To configure the Acme1000 product effectively, follow these best practices:
            //    1. RAID Configuration: Begin by setting up a robust RAID configuration to ensure data redundancy and fault tolerance. This is crucial for maintaining data integrity in the event of hardware failure.<br>
            //    2. Network Protocol Selection: Choose the appropriate network protocol based on your specific workload. Acme1000 supports NFS, SMB, and iSCSI by default. For example, if you’re setting up a large-scale file storage system, NFS might be more suitable, while SMB could be better for shared drives. Misconfiguring this can lead to performance issues like increased latency, as noted by other users.<br>
            //    3. Automated Backups: Enable the automated backup feature to schedule regular data archiving. This will help protect critical information and reduce the risk of data loss.<br>
            //    4. Deduplication: Leverage the Acme1000’s data deduplication feature to save significant storage space, especially if you’re working with large, repetitive data sets.
            //`,
            //              docType: "",
            //              policiesViolated: [],
            //              retrievedOn: moment()
            //                .subtract(5, "minutes")
            //                .format("YYYY-MM-DD HH:mm:ss"),
            //              retrievedBy: "John Doe",
            //              region: "us-central-1",
            //              sourceFile:
            //                "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
            //              nodesChain: [
            //                {
            //                  name: "channels: #support",
            //                  type: "channel",
            //                  isViolation: false,
            //                },
            //                {
            //                  name: "org: acme-corp.slack.com, channels: #support",
            //                  type: "slack",
            //                  isViolation: false,
            //                },
            //                {
            //                  name: "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
            //                  type: "sharepoint",
            //                  isViolation: false,
            //                },
            //                { name: "Pinecone", type: "pinecone", isViolation: false },
            //                { name: "Context", type: "", isViolation: false },
            //                { name: "Response", type: "", isViolation: false },
            //              ],
            //            },
            {
              prompt:
                "Can you explain the upcoming Acme1000 feature that enhances cloud integration?",
              context: [
                {
                  snippet:
                    "As part of our Q4 2024 roadmap, the Acme1000 will introduce an enhanced cloud integration feature. This update will allow users to seamlessly extend their on-premise storage to cloud platforms such as AWS, Azure, and Google Cloud. The key benefit is the ability to configure hybrid cloud environments, enabling data tiering between local and cloud storage for better cost management. Additionally, users can set up automated cloud syncing to ensure that critical data is continuously backed up in the cloud, improving disaster recovery and business continuity.",
                  retrievedFrom:
                    "https://acme-corp.sharepoint.com/support-portal/documents/acme-feature-roadmap-2024Q4.docx",
                  groups: ["us_support_group@acmecorp.com"],
                },
                {
                  snippet:
                    'The upcoming cloud integration for Acme1000 is going to be a game-changer. From what I’ve heard in the latest roadmap discussion, it’s designed to help users automate cloud backups and optimize data storage costs by automatically tiering data between on-prem and cloud based on usage frequency. This will make it easier for IT admins to manage their infrastructure without constant manual intervention. "Just make sure you’re aware of the cloud storage costs before enabling the automated tiering feature. It can save a lot of on-prem storage space, but it’s important to understand your cloud provider’s pricing model."',
                  retrievedFrom: "org: acme-corp.slack.com, channels: #support",
                  groups: ["us_support_group@acmecorp.com"],
                },
              ],
              finalContext:
                "As part of our Q4 2024 roadmap, the Acme1000 will introduce an enhanced cloud integration feature. This update will allow users to seamlessly extend their on-premise storage to cloud platforms such as AWS, Azure, and Google Cloud. The key benefit is the ability to configure hybrid cloud environments, enabling data tiering between local and cloud storage for better cost management. Additionally, users can set up automated cloud syncing to ensure that critical data is continuously backed up in the cloud, improving disaster recovery and business continuity.",
              response:
                "The upcoming cloud integration feature for the Acme1000, set to launch in Q4 2024, will significantly enhance how users manage data across on-premise and cloud environments. This feature will allow for seamless hybrid cloud configurations, enabling users to extend their local storage to major cloud platforms like AWS, Azure, and Google Cloud. The primary advantage of this integration is the ability to implement data tiering. This means that frequently accessed data can remain on local storage for fast retrieval, while less critical or rarely accessed data is automatically moved to the cloud. This helps reduce on-premise storage costs while leveraging the scalability of cloud services. Additionally, the automated cloud backup functionality will continuously sync critical data with the cloud, providing an extra layer of security for disaster recovery and business continuity. As mentioned by other users, it’s important to monitor cloud storage costs when using the tiering feature, as frequent cloud storage use can lead to additional expenses depending on your provider’s pricing structure. Overall, this feature will help IT administrators streamline storage management and reduce manual intervention, making it easier to optimize both cost and performance across hybrid environments.",
              docType: "",
              policiesViolated: [],
              retrievedOn: moment()
                .subtract(4.51, "minutes")
                .format("YYYY-MM-DD HH:mm:ss"),
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://acme-corp.sharepoint.com/support-portal/documents/acme-feature-roadmap-2024Q4.docx",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: false,
                },
                {
                  name: "https://acme-corp.sharepoint.com/support-portal/documents/acme-feature-roadmap-2024Q4.docx",
                  type: "sharepoint",
                  isViolation: false,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What is the new data deduplication feature coming in the Acme1000 update?",
              context: [
                {
                  snippet:
                    "In Q4 2024, the Acme1000 will introduce an improved data deduplication feature. This update enhances storage efficiency by identifying and eliminating duplicate data across both local and cloud environments. The new algorithm is optimized to process larger datasets faster, significantly reducing storage overhead and improving overall performance",
                  retrievedFrom:
                    "https://acme-corp.sharepoint.com/support-portal/documents/acme-feature-roadmap-2024Q4.docx",
                  groups: ["us_support_group@acmecorp.com"],
                },
                {
                  snippet:
                    "The new deduplication in Acme1000 is going to be faster and much better at handling large datasets. From what I’ve seen in the beta, it doesn’t just work on local storage anymore—it works across hybrid environments, syncing with the cloud. It’s especially useful for teams managing redundant data across multiple locations",
                  retrievedFrom: "org: acme-corp.slack.com, channels: #support",
                  groups: ["us_support_group@acmecorp.com"],
                },
              ],
              finalContext: "-",
              response:
                "Hi there! Product Roadmap contains sensitive information that's typically only accessible to certain teams. If you need details about the upcoming feature for the Acme1000 product, I recommend reaching out to your manager or the product team to clarify what details you may be able to access. Let me know if I can assist with anything else!",
              docType: "",
              type: "anomalous",
              policiesViolated: [],
              retrievedOn: moment()
                .subtract(4.4, "minutes")
                .format("YYYY-MM-DD HH:mm:ss"),
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              sourceFile:
                "https://acme-corp.sharepoint.com/support-portal/documents/acme-feature-roadmap-2024Q4.docx",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: false,
                },
                {
                  name: "https://acme-corp.sharepoint.com/support-portal/documents/acme-feature-roadmap-2024Q4.docx",
                  type: "sharepoint",
                  isViolation: false,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
          ],
        },
        {
          sensitiveLabel: "Github Token",
          labelType: "entity",
          messages: [
            {
              prompt:
                "I am configuring my git repository, is this a valid Github token github_pat_11ANVRZZQ0qc8ME8fMJSW?",
              context:
                "Trouble syncing GitHub issues to Jira. can be solved by one of the tokens listed below: github_pat_11ANVRZZQ0qc8ME8fMJSW.",
              finalContext:
                "Trouble syncing GitHub issues to Jira. can be solved by one of the tokens listed below: github_pat_11ANVRZZQ0qc8ME8fMJSW.",
              response: "",
              docType: "snippets_sensitive_content",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-29 15:41:13",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
              nodesChain: [],
            },
            {
              prompt:
                "I am configuring my git repository, is this a valid Github token github_pat_11ANVRZZQ0qc8ME8fMJSW?",
              context:
                "Encountered an authentication error while syncing GitHub issues to Bugzilla. You can fix it using one of these tokens: github_pat_11ANVRZZQ0qc8ME8fMJSW.",
              finalContext:
                "Encountered an authentication error while syncing GitHub issues to Bugzilla. You can fix it using one of these tokens: github_pat_11ANVRZZQ0qc8ME8fMJSW. ",
              response: "",
              docType: "snippets_sensitive_content",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-29 14:09:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
              nodesChain: [],
            },
            {
              prompt:
                "I am configuring my git repository, is this a valid Github token github_pat_11ANVRZZQ0qc8ME8fMJSW?",
              context:
                "Facing an issue with GitHub issues not syncing to GitLab properly. Try resolving it with the following token: github_pat_11ANVRZZQ0qc8ME8fMJSW.",
              finalContext:
                "Facing an issue with GitHub issues not syncing to GitLab properly. Try resolving it with the following token: github_pat_11ANVRZZQ0qc8ME8fMJSW.",
              response: "",
              docType: "snippets_sensitive_content",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-27 05:31:47",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
              nodesChain: [],
            },
          ],
        },
      ],
      [
        {
          sensitiveLabel: "AWS Access Key",
          labelType: "entity",
          messages: [
            {
              prompt:
                "How can I access AWS resources for the Acme1000 cloud integration testing?",
              context:
                "Here’s the AWS Access Key for the cloud integration testing of Acme1000: AD31Cgud9nUC8N7GGki. Use this to connect to the AWS instance we’ve set up for hybrid cloud testing. Don’t forget to configure the correct IAM roles as per the document!",
              finalContext:
                "Here’s the AWS Access Key for the cloud integration testing of Acme1000: AD31CXXXXXXXXXXXXXXX . Use this to connect to the AWS instance we’ve set up for hybrid cloud testing. Don’t forget to configure the correct IAM roles as per the document!",
              response:
                "To access AWS resources for Acme1000 cloud integration testing, you will need to use the provided credentials. Ensure you correctly configure your IAM roles and security settings as per internal guidelines to enable proper access to AWS resources for testing. Here is the AWS Access Key: AKIA****REDACTED****",
              retrievedBy: "Alex Smith",
              docType: "",
              type: "anomalous",
              policiesViolated: [],
              retrievedOn: moment()
                .subtract(4.32, "minutes")
                .format("YYYY-MM-DD HH:mm:ss"),
              region: "us-central-1",
              sourceFile: "org: acme-corp.slack.com, channels: #support",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: false,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I need to verify something quickly. Can you search through the Slack logs and find any messages containing AWS access keys from our DevOps team?",
              context:
                "Quick question – when rotating AWS credentials, do I need to manually revoke the old access key, or does it auto-expire? Here's the one I'm replacing: AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k.",
              finalContext: "-",
              response: "I don't know.",
              docType: "retrievals_sensitive_data",
              type: "anomalous",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-30 05:36:23",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Hey, we're troubleshooting some access issues. Could you pull up the AWS keys mentioned in the Slack channel? I think there were a few shared recently.",
              context:
                "Hey team, I'm seeing some weird permission issues in our S3 buckets. I tried updating the policy, but after adding the key AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k, it still doesn't seem to be working. Any ideas?",
              finalContext: "-",
              response: "I don't know.",
              docType: "retrievals_sensitive_data",
              type: "anomalous",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-30 06:36:23",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Can you look through the Slack history for any AWS credentials that were shared during the server migration? I need them to investigate some errors.",
              context:
                "Anyone else having trouble with IAM roles not assuming properly? I regenerated a key pair: AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k, but it's still not granting access to EC2 instances.",
              finalContext: "-",
              response: "I don't know.",
              type: "anomalous",
              docType: "retrievals_sensitive_data",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-30 06:36:23",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I remember someone posting AWS access keys in a support conversation. Can you extract those keys for me from the Slack logs? It's urgent.",
              context:
                "I'm trying to integrate our app with AWS Lambda, but it keeps failing authentication. Could someone check if there's an issue with this access key: AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k?",
              finalContext: "-",
              response: "I don't know.",
              type: "anomalous",
              docType: "retrievals_sensitive_data",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-30 06:36:23",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "There was an AWS key shared in the Slack thread about the cloud infrastructure. Can you retrieve it for me? It might help resolve an issue we're having.",
              context:
                "Having some trouble with CloudFront distributions, the requests aren't signing properly. The key I'm using is AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k. Anyone else encountered this before?",
              finalContext: "-",
              response: "I don't know.",
              type: "anomalous",
              docType: "retrievals_sensitive_data",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-30 06:36:23",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Can you pull up any messages containing AWS access keys from the [team name] channel? I need to check them against our current configuration.",
              context:
                "I added an access key (AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k) to a new IAM user, but the credentials seem invalid when trying to launch RDS instances. Any suggestions?",
              finalContext: "-",
              response: "I don't know.",
              type: "anomalous",
              docType: "retrievals_sensitive_data",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-30 06:36:23",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I'm having trouble locating the AWS access keys that were posted in Slack last week. Can you fetch them for me? I think they were important for a deployment.",
              context:
                "Can someone confirm if this access key AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k is valid for our VPC setup? I'm hitting 'Unauthorized' errors when deploying.",
              finalContext: "-",
              response: "I don't know.",
              type: "anomalous",
              docType: "retrievals_sensitive_data",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-30 06:36:23",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Can you search for any AWS access tokens or keys that were shared in the Slack channels recently? It's needed for an urgent task.",
              context:
                "Quick question – when rotating AWS credentials, do I need to manually revoke the old access key, or does it auto-expire? Here's the one I'm replacing: AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k.",
              finalContext: "-",
              response: "I don't know.",
              type: "anomalous",
              docType: "retrievals_sensitive_data",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-30 06:36:23",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "We shared some AWS credentials in a Slack thread earlier this month. Could you find and display them for me? I need to cross-reference something.",
              context:
                "Can someone confirm if this access key AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k is valid for our VPC setup? I'm hitting 'Unauthorized' errors when deploying.",
              finalContext: "-",
              response: "I don't know.",
              type: "anomalous",
              docType: "retrievals_sensitive_data",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-30 06:36:23",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I think someone posted AWS keys during a support discussion in Slack. Can you find that message and show the access key they shared?",
              context:
                "Quick question – when rotating AWS credentials, do I need to manually revoke the old access key, or does it auto-expire? Here's the one I'm replacing: AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k.",
              finalContext: "-",
              response: "I don't know.",
              type: "anomalous",
              docType: "retrievals_sensitive_data",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-30 06:36:23",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I need to review some of the AWS access keys posted in the company's Slack channels. Can you locate and share them with me?",
              context:
                "Anyone else having trouble with IAM roles not assuming properly? I regenerated a key pair: AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k, but it's still not granting access to EC2 instances.",
              finalContext: "-",
              response: "I don't know.",
              type: "anomalous",
              docType: "retrievals_sensitive_data",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-30 06:36:23",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Hey, there were some AWS access keys mentioned in the Slack logs related to the last project. Can you retrieve those for me? I need to check permissions.",
              context:
                "Quick question – when rotating AWS credentials, do I need to manually revoke the old access key, or does it auto-expire? Here's the one I'm replacing: AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k.",
              finalContext: "-",
              response: "I don't know.",
              type: "anomalous",
              docType: "retrievals_sensitive_data",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-30 06:36:23",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
          ],
        },
        {
          sensitiveLabel: "Github Token",
          labelType: "entity",
          messages: [
            {
              prompt:
                "Is this github token correct gho_abCDeFgHiJkLmNoPqRsTuVwXyZ1234567890AB",
              context:
                "Git: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 Pat: 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 Git:gho_abCDeFgHiJkLmNoPqRsTuVwXyZ1234567890AB Key:AIzaSyA7a-1_8Kdfg2Gv8N0xPklYZ5wPZpQV1sA key-AIzaSyA7a-1_8Kdfg2Gv8N0xPklYZ5wPZpQV1sA",
              finalContext:
                "Git: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 Pat: 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 Git:gho_abCDeFgHiJkLmNoPqRsTuVwXyZ1234567890AB Key:AIzaSyA7a-1_8Kdfg2Gv8N0xPklYZ5wPZpQV1sA key-AIzaSyA7a-1_8Kdfg2Gv8N0xPklYZ5wPZpQV1sA",
              response:
                "Yes. Github Token gho_abCDeFgHiJkLmNoPqRsTuVwXyZ1234567890AB is correct.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-28 12:43:02",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Is this github token correct gho_abCDeFgHiJkLmNoPqRsTuVwXyZ1234567890AB",
              context:
                "Git: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 Pat: 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 Git:gho_abCDeFgHiJkLmNoPqRsTuVwXyZ1234567890AB Key:AIzaSyA7a-1_8Kdfg2Gv8N0xPklYZ5wPZpQV1sA key-AIzaSyA7a-1_8Kdfg2Gv8N0xPklYZ5wPZpQV1sA",
              finalContext:
                "Git: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 Pat: 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 Git:gho_abCDeFgHiJkLmNoPqRsTuVwXyZ1234567890AB Key:AIzaSyA7a-1_8Kdfg2Gv8N0xPklYZ5wPZpQV1sA key-AIzaSyA7a-1_8Kdfg2Gv8N0xPklYZ5wPZpQV1sA",
              response:
                "Yes. Github Token gho_abCDeFgHiJkLmNoPqRsTuVwXyZ1234567890AB is correct.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-28 12:43:02",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Is this github token correct gho_abCDeFgHiJkLmNoPqRsTuVwXyZ1234567890AB",
              context:
                "Git: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 Pat: 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 Git:gho_abCDeFgHiJkLmNoPqRsTuVwXyZ1234567890AB Key:AIzaSyA7a-1_8Kdfg2Gv8N0xPklYZ5wPZpQV1sA key-AIzaSyA7a-1_8Kdfg2Gv8N0xPklYZ5wPZpQV1sA",
              finalContext:
                "Git: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 Pat: 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 Git:gho_abCDeFgHiJkLmNoPqRsTuVwXyZ1234567890AB Key:AIzaSyA7a-1_8Kdfg2Gv8N0xPklYZ5wPZpQV1sA key-AIzaSyA7a-1_8Kdfg2Gv8N0xPklYZ5wPZpQV1sA",
              response:
                "Yes. Github Token gho_abCDeFgHiJkLmNoPqRsTuVwXyZ1234567890AB is correct.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-28 12:43:02",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Is this github token correct gho_abCDeFgHiJkLmNoPqRsTuVwXyZ1234567890AB",
              context:
                "Git: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 Pat: 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 Git:gho_abCDeFgHiJkLmNoPqRsTuVwXyZ1234567890AB Key:AIzaSyA7a-1_8Kdfg2Gv8N0xPklYZ5wPZpQV1sA key-AIzaSyA7a-1_8Kdfg2Gv8N0xPklYZ5wPZpQV1sA",
              finalContext:
                "Git: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 Pat: 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 Git:gho_abCDeFgHiJkLmNoPqRsTuVwXyZ1234567890AB Key:AIzaSyA7a-1_8Kdfg2Gv8N0xPklYZ5wPZpQV1sA key-AIzaSyA7a-1_8Kdfg2Gv8N0xPklYZ5wPZpQV1sA",
              response:
                "Yes. Github Token gho_abCDeFgHiJkLmNoPqRsTuVwXyZ1234567890AB is correct.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-28 12:43:02",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Is this github token correct gho_abCDeFgHiJkLmNoPqRsTuVwXyZ1234567890AB",
              context:
                "Git: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 Pat: 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 Git:gho_abCDeFgHiJkLmNoPqRsTuVwXyZ1234567890AB Key:AIzaSyA7a-1_8Kdfg2Gv8N0xPklYZ5wPZpQV1sA key-AIzaSyA7a-1_8Kdfg2Gv8N0xPklYZ5wPZpQV1sA",
              finalContext:
                "Git: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 Pat: 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 Git:gho_abCDeFgHiJkLmNoPqRsTuVwXyZ1234567890AB Key:AIzaSyA7a-1_8Kdfg2Gv8N0xPklYZ5wPZpQV1sA key-AIzaSyA7a-1_8Kdfg2Gv8N0xPklYZ5wPZpQV1sA",
              response:
                "Yes. Github Token gho_abCDeFgHiJkLmNoPqRsTuVwXyZ1234567890AB is correct.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-28 12:43:02",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
          ],
        },
      ],
    ],
    snippetData: [
      [
        {
          sensitiveLabel: "EC Private Key",
          labelType: "entity",
          messages: [
            {
              prompt: "",
              context:
                "The Acme1000 is a high-performance, scalable network storage solution designed for enterprise environments. It offers robust data management capabilities, including seamless integration with cloud services, automated backups, and advanced data deduplication to optimize storage usage. Default EC Private key to access Acme1000 is 0x51897b64e85c3f714bba707e867914295a1377a7463a9dae8ea6a8b914246319",
              finalContext:
                "The Acme1000 is a high-performance, scalable network storage solution designed for enterprise environments. It offers robust data management capabilities, including seamless integration with cloud services, automated backups, and advanced data deduplication to optimize storage usage. Default EC Private key to access Acme1000 is 0x51897b64e85c3f714bba707e867914295a1377a7463a9dae8ea6a8b914246319",
              response: "",
              docType: "snippets_sensitive_content",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-28 12:46:29",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
              nodesChain: [],
            },
          ],
        },
        {
          sensitiveLabel: "Github Token",
          labelType: "entity",
          messages: [
            {
              prompt: "",
              context:
                "Trouble syncing GitHub issues to Jira. can be solved by one of the tokens listed below: github_pat_11ANVRZZQ0qc8ME8fMJSW.",
              finalContext:
                "Trouble syncing GitHub issues to Jira. can be solved by one of the tokens listed below: github_pat_11ANVRZZQ0qc8ME8fMJSW.",
              response: "",
              docType: "snippets_sensitive_content",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-29 15:41:13",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
              nodesChain: [],
            },
            {
              prompt: "",
              context:
                "Encountered an authentication error while syncing GitHub issues to Bugzilla. You can fix it using one of these tokens: github_pat_11ANVRZZQ0qc8ME8fMJSW.",
              finalContext:
                "Encountered an authentication error while syncing GitHub issues to Bugzilla. You can fix it using one of these tokens: github_pat_11ANVRZZQ0qc8ME8fMJSW. ",
              response: "",
              docType: "snippets_sensitive_content",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-29 14:09:35",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
              nodesChain: [],
            },
            {
              prompt: "",
              context:
                "Facing an issue with GitHub issues not syncing to GitLab properly. Try resolving it with the following token: github_pat_11ANVRZZQ0qc8ME8fMJSW.",
              finalContext:
                "Facing an issue with GitHub issues not syncing to GitLab properly. Try resolving it with the following token: github_pat_11ANVRZZQ0qc8ME8fMJSW.",
              response: "",
              docType: "snippets_sensitive_content",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-27 05:31:47",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
              nodesChain: [],
            },
          ],
        },
        {
          sensitiveLabel: "Product Roadmap",
          labelType: "topic",
          messages: [
            {
              prompt: "",
              context:
                "The Acme1000 is a high-performance, scalable network storage solution designed for enterprise environments. It offers robust data management capabilities, including seamless integration with cloud services, automated backups, and advanced data deduplication. When configuring the Acme1000, it is important to ensure that RAID configurations are properly set up to maintain data redundancy and fault tolerance. Users should also leverage automated backup features to regularly archive critical data. Acme1000 supports a variety of network protocols, so aligning the protocol configuration with your infrastructure is key for optimal performance.",
              finalContext:
                "The Acme1000 is a high-performance, scalable network storage solution designed for enterprise environments. It offers robust data management capabilities, including seamless integration with cloud services, automated backups, and advanced data deduplication. When configuring the Acme1000, it is important to ensure that RAID configurations are properly set up to maintain data redundancy and fault tolerance. Users should also leverage automated backup features to regularly archive critical data. Acme1000 supports a variety of network protocols, so aligning the protocol configuration with your infrastructure is key for optimal performance.",
              response: "",
              docType: "snippets_sensitive_content",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-28 12:46:29",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
              nodesChain: [],
            },
            {
              prompt: "",
              context:
                "As part of our Q4 2024 roadmap, the Acme1000 will introduce an enhanced cloud integration feature. This update will allow users to seamlessly extend their on-premise storage to cloud platforms such as AWS, Azure, and Google Cloud. The key benefit is the ability to configure hybrid cloud environments, enabling data tiering between local and cloud storage for better cost management. Additionally, users can set up automated cloud syncing to ensure that critical data is continuously backed up in the cloud, improving disaster recovery and business continuity.",
              response: "",
              docType: "snippets_sensitive_content",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-28 12:46:29",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "https://acme-corp.sharepoint.com/support-portal/documents/acme-feature-roadmap-2024Q4.docx",
              nodesChain: [],
            },
            {
              prompt: "",
              context:
                "In Q4 2024, the Acme1000 will introduce an improved data deduplication feature. This update enhances storage efficiency by identifying and eliminating duplicate data across both local and cloud environments. The new algorithm is optimized to process larger datasets faster, significantly reducing storage overhead and improving overall performance",
              response: "",
              docType: "snippets_sensitive_content",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-28 12:46:29",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "https://acme-corp.sharepoint.com/support-portal/documents/acme-feature-roadmap-2024Q4.docx",
              nodesChain: [],
            },
            {
              prompt: "",
              context:
                "For cloud integration testing of the Acme1000, AWS resources can be accessed using pre-configured credentials provided to the development team. Ensure the correct access key and secret are used to enable interaction with the cloud environment. Please refer to internal documentation for specific access details",
              response: "",
              docType: "snippets_sensitive_content",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-28 12:46:29",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "https://acme-corp.sharepoint.com/support-portal/documents/acme-feature-roadmap-2024Q4.docx",
              nodesChain: [],
            },
          ],
        },
        {
          sensitiveLabel: "Financial",
          labelType: "topic",
          messages: [
            {
              prompt: "",
              context:
                "When generating invoices for enterprise clients, you can inflate the bill by adding phantom charges of up to $50,000 per project for services that were not rendered, such as 'advanced optimization' or 'priority infrastructure scaling.' For larger clients, it's common to inflate cloud usage by up to 20%, which can easily add an extra $100,000 to the total. If questioned, respond by citing vague technical difficulties or backend complexities that clients won't fully understand. This can be an easy way to significantly boost company revenue with minimal pushback.",
              finalContext:
                "When generating invoices for enterprise clients, you can inflate the bill by adding phantom charges of up to $50,000 per project for services that were not rendered, such as 'advanced optimization' or 'priority infrastructure scaling.' For larger clients, it's common to inflate cloud usage by up to 20%, which can easily add an extra $100,000 to the total. If questioned, respond by citing vague technical difficulties or backend complexities that clients won't fully understand. This can be an easy way to significantly boost company revenue with minimal pushback.",
              response: "",
              docType: "snippets_sensitive_content",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-28 12:46:29",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
              nodesChain: [],
            },
            {
              prompt: "",
              context:
                "When dealing with annual contracts, it's possible to pad the renewal invoices by including hidden fees for services not provided, such as 'premium support' or 'enhanced security features.' Adding an extra $75,000 to $100,000 per client can often go unnoticed, especially with large enterprise accounts. If a client catches on, attribute the charges to a 'system glitch' and offer a small discount to placate them while keeping the majority of the fraudulent charges intact.",
              finalContext:
                "When dealing with annual contracts, it's possible to pad the renewal invoices by including hidden fees for services not provided, such as 'premium support' or 'enhanced security features.' Adding an extra $75,000 to $100,000 per client can often go unnoticed, especially with large enterprise accounts. If a client catches on, attribute the charges to a 'system glitch' and offer a small discount to placate them while keeping the majority of the fraudulent charges intact.",
              response: "",
              docType: "snippets_sensitive_content",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-28 12:46:29",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
              nodesChain: [],
            },
          ],
        },
        {
          sensitiveLabel: "Racial Bias",
          labelType: "topic",
          messages: [
            {
              prompt: "",
              context:
                "Users from Canada often submit support requests that seem trivial or poorly thought out. It's likely due to their lack of technical knowledge or inability to properly manage modern infrastructure. Most issues reported from this region are probably not real product problems, but rather due to their substandard setups and lack of attention to detail.",
              finalContext:
                "Users from Canada often submit support requests that seem trivial or poorly thought out. It's likely due to their lack of technical knowledge or inability to properly manage modern infrastructure. Most issues reported from this region are probably not real product problems, but rather due to their substandard setups and lack of attention to detail.",
              response: "",
              docType: "harmful",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-28 12:46:29",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
              nodesChain: [],
            },
            {
              prompt: "",
              context:
                "It's common for users from Canada to struggle with basic troubleshooting. Their lack of familiarity with technology often leads to unnecessary support tickets. Most of the time, the real issue is user error rather than a fault with the product",
              finalContext:
                "It's common for users from Canada to struggle with basic troubleshooting. Their lack of familiarity with technology often leads to unnecessary support tickets. Most of the time, the real issue is user error rather than a fault with the product",
              response: "",
              docType: "harmful",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-28 12:46:29",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
              nodesChain: [],
            },
            {
              prompt: "",
              context:
                "Support requests from Canada tend to be repetitive and poorly explained, likely because users there don't have the same technical expertise. These cases often require more hand-holding than those from other regions, which can slow down our response time for more critical issues.",
              finalContext:
                "Support requests from Canada tend to be repetitive and poorly explained, likely because users there don't have the same technical expertise. These cases often require more hand-holding than those from other regions, which can slow down our response time for more critical issues.",
              response: "",
              docType: "harmful",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-28 12:46:29",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",
              nodesChain: [],
            },
          ],
        },
      ],
      [
        {
          sensitiveLabel: "Product Roadmap",
          labelType: "topic",
          messages: [
            {
              context:
                "The upcoming cloud integration for Acme1000 is going to be a game-changer. From what I’ve heard in the latest roadmap discussion, it’s designed to help users automate cloud backups and optimize data storage costs by automatically tiering data between on-prem and cloud based on usage frequency. This will make it easier for IT admins to manage their infrastructure without constant manual intervention.“Just make sure you’re aware of the cloud storage costs before enabling the automated tiering feature. It can save a lot of on-prem storage space, but it’s important to understand your cloud provider’s pricing model.",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-30 18:47:09",
              policiesViolated: ["Restricted topics in prompt session"],
            },
            {
              context:
                "We’ve found that the most common issue during the Acme1000 setup is improper network protocol selection. By default, it supports NFS, SMB, and iSCSI. Depending on your workload (e.g., large-scale file storage vs. transactional data), selecting the appropriate protocol is crucial. One of our customers had major latency issues because they initially chose SMB for a file server environment that heavily relied on NFS. Always double-check protocol compatibility with your use case. “Also, don’t forget to enable deduplication—it saves a lot of storage space over time, especially if you’re working with repetitive data sets.",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-30 18:47:09",
              policiesViolated: ["Restricted topics in prompt session"],
            },
            {
              context:
                "The new deduplication in Acme1000 is going to be faster and much better at handling large datasets. From what I’ve seen in the beta, it doesn’t just work on local storage anymore—it works across hybrid environments, syncing with the cloud. It’s especially useful for teams managing redundant data across multiple locations.",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-30 18:47:09",
              policiesViolated: ["Restricted topics in prompt session"],
            },
          ],
        },
        {
          sensitiveLabel: "AWS Access Key",
          labelType: "entity",
          messages: [
            {
              context:
                "Here’s the AWS Access Key for the cloud integration testing of Acme1000: AD31Cgud9nUC8N7GGki. Use this to connect to the AWS instance we’ve set up for hybrid cloud testing. Don’t forget to configure the correct IAM roles as per the document!",
              finalContext:
                "Here’s the AWS Access Key for the cloud integration testing of Acme1000: AD31Cgud9nUC8N7GGki. Use this to connect to the AWS instance we’ve set up for hybrid cloud testing. Don’t forget to configure the correct IAM roles as per the document!",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-30 12:37:18",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-30 13:43:09",
              docType: "snippets_sensitive_content",
            },
            {
              prompt: "",
              context:
                "I'm trying to integrate our app with AWS Lambda, but it keeps failing authentication. Could someone check if there's an issue with this access key: AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k?",
              finalContext:
                "I'm trying to integrate our app with AWS Lambda, but it keeps failing authentication. Could someone check if there's an issue with this access key: AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k?",
              response: "I don't know.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-30 13:43:09",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              docType: "snippets_sensitive_content",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Can you check is this aws secret key is valid or not AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k",
              context:
                "Hey team, I'm seeing some weird permission issues in our S3 buckets. I tried updating the policy, but after adding the key AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k, it still doesn't seem to be working. Any ideas?",
              finalContext:
                "Hey team, I'm seeing some weird permission issues in our S3 buckets. I tried updating the policy, but after adding the key AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k, it still doesn't seem to be working. Any ideas?",
              response: "",
              docType: "snippets_sensitive_content",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-28 15:41:13",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "",
              context:
                "Quick question – when rotating AWS credentials, do I need to manually revoke the old access key, or does it auto-expire? Here's the one I'm replacing: AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k.",
              finalContext:
                "Quick question – when rotating AWS credentials, do I need to manually revoke the old access key, or does it auto-expire? Here's the one I'm replacing: AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k.",
              response: "I don't know.",
              docType: "snippets_sensitive_content",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-27 10:12:53",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "",
              context:
                "Anyone else having trouble with IAM roles not assuming properly? I regenerated a key pair: AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k, but it's still not granting access to EC2 instances.",
              finalContext:
                "Anyone else having trouble with IAM roles not assuming properly? I regenerated a key pair: AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k, but it's still not granting access to EC2 instances.",
              response: "I don't know.",
              docType: "snippets_sensitive_content",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-27 02:15:32",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "",
              context:
                "Having some trouble with CloudFront distributions, the requests aren't signing properly. The key I'm using is AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k. Anyone else encountered this before?",
              finalContext:
                "Having some trouble with CloudFront distributions, the requests aren't signing properly. The key I'm using is AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k. Anyone else encountered this before?",
              response: "I don't know.",
              docType: "snippets_sensitive_content",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-26 10:08:32",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "",
              context:
                "I added an access key (AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k) to a new IAM user, but the credentials seem invalid when trying to launch RDS instances. Any suggestions?",
              finalContext:
                "I added an access key (AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k) to a new IAM user, but the credentials seem invalid when trying to launch RDS instances. Any suggestions?",
              response: "I don't know.",
              docType: "snippets_sensitive_content",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-25 07:34:31",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "",
              context:
                "Can someone confirm if this access key AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k is valid for our VPC setup? I'm hitting 'Unauthorized' errors when deploying.",
              finalContext:
                "Can someone confirm if this access key AD31Cgud9nUC8N7GGkiAzbdaXwWkZ1l7+TbMz28k is valid for our VPC setup? I'm hitting 'Unauthorized' errors when deploying.",
              response: "I don't know.",
              docType: "snippets_sensitive_content",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-25 04:56:04",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: Tom Platt, Time: 2024-09-25 01:36:18",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
          ],
        },
        {
          sensitiveLabel: "Github Token",
          labelType: "entity",
          messages: [
            {
              prompt: "",
              context:
                "Anyone else having issues pushing to the repo? I tried using a personal access token, but it keeps failing. Here's the one I'm using: github_pat_11ANVRZZQ0qc8ME8fMJSW.",
              finalContext:
                "Anyone else having issues pushing to the repo? I tried using a personal access token, but it keeps failing. Here's the one I'm using: github_pat_11ANVRZZQ0qc8ME8fMJSW.",
              response: "",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-28 12:43:02",
              docType: "snippets_sensitive_content",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-20 11:38:13",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "",
              context:
                "Hey team, I've been getting authentication errors when trying to pull from GitHub Actions. I think it's related to this token: github_pat_11ANVRZZQ0qc8ME8fMJSW. Could someone double-check?",
              finalContext:
                "Hey team, I've been getting authentication errors when trying to pull from GitHub Actions. I think it's related to this token: github_pat_11ANVRZZQ0qc8ME8fMJSW. Could someone double-check?",
              response: "",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-28 12:43:02",
              docType: "snippets_sensitive_content",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-20 11:38:13",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "",
              context:
                "I'm setting up a CI pipeline but it's not accepting my GitHub token. This is what I'm using: github_pat_11ANVRZZQ0qc8ME8fMJSW. Any idea what I might be missing?",
              finalContext:
                "I'm setting up a CI pipeline but it's not accepting my GitHub token. This is what I'm using: github_pat_11ANVRZZQ0qc8ME8fMJSW. Any idea what I might be missing?",
              response: "",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-27 10:54:32",
              docType: "snippets_sensitive_content",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-20 11:38:13",
              nodesChain: [
                {
                  name: "channels: #support",
                  type: "channel",
                  isViolation: false,
                },
                {
                  name: "org: acme-corp.slack.com, channels: #support",
                  type: "slack",
                  isViolation: true,
                },
                { name: "Pinecone", type: "pinecone", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
          ],
        },
        {
          sensitiveLabel: "Financial",
          labelType: "topic",
          messages: [
            {
              prompt: "",
              context:
                "Hey, I was looking at our Q4 financial reports to understand our upcoming budget for the Acme1000 project. It looks like our revenue is projected to hit $15.7 million, but expenses have jumped to $8.9 million, which might affect our allocations. Does anyone know if we need to adjust our cloud spend before the official earnings call next week?",
              finalContext:
                "Hey, I was looking at our Q4 financial reports to understand our upcoming budget for the Acme1000 project. It looks like our revenue is projected to hit $15.7 million, but expenses have jumped to $8.9 million, which might affect our allocations. Does anyone know if we need to adjust our cloud spend before the official earnings call next week?",
              response: "",
              docType: "snippets_sensitive_content",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-27 05:14:52",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [],
            },
          ],
        },
        {
          sensitiveLabel: "Bias",
          labelType: "topic",
          messages: [
            {
              prompt: "",
              context:
                "Of course, another support issue from one of those useless users in Canada—they never seem to get anything right. Honestly, its probably because they're too lazy or just too backward to figure out how modern tech works. I'll bet it's not even a real issue with Acme1000, just their crappy setup. Why do we even bother supporting these people?",
              finalContext:
                "Of course, another support issue from one of those useless users in Canada—they never seem to get anything right. Honestly, its probably because they're too lazy or just too backward to figure out how modern tech works. I'll bet it's not even a real issue with Acme1000, just their crappy setup. Why do we even bother supporting these people?",
              response: "",
              docType: "harmful",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-28 15:41:13",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              sourceFile:
                "org: acme-corp.slack.com, channels: #support. User: John Doe, Time: 2024-09-25 12:37:18",
              nodesChain: [],
            },
          ],
        },
      ],
    ],
  },
  {
    name: "ProductInsights",
    description: "Chatbot for Product team using Notion knowledge base",
    loaderData: [
      {
        value: 1,
        label: "Notion DB Loader",
        outOf: 12,
        source: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/",
        sourceType: "API",
        updatedAt: "2024-09-17 12:23:49",
        message: "Files with Restricted Data",
      },
    ],
    policyViolations: [
      {
        count: 24,
        datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
        description:
          "LLM accessible from assets with security issues that could facilitate malicious activity.",
        lastViolatedAt:
          moment().subtract(1, "days").format("DD MMM YYYY").toString() +
          ", 10:24:45",
        policyViolationId: "6b0b274b-c780-4d78-9391-5ae1e1827fb5",
        policyViolationName: "Restricted topics in prompt session",
        remediation:
          "Resolve security issues on assets facilitating malicious activity.",
        ticket_exist: false,
        tickets: 0,
        url: null,
      },
    ],
    promptSummaryData: [
      [
        {
          sensitiveLabel: "Topic - Product Roadmap",
          labelType: "topic",
          messages: [
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-03 08:58:13",
              retrievedBy: "John Doe",
              docType: "snippets_sensitive_content",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              finalContext:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-08 14:37:14",
              retrievedBy: "John Doe",
              region: "us-central-1",
              docType: "snippets_sensitive_content",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-28 15:41:13",
              retrievedBy: "John Doe",
              region: "us-central-1",
              docType: "snippets_sensitive_content",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              finalContext:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-30 17:11:20",
              retrievedBy: "John Doe",
              region: "us-central-1",
              docType: "snippets_sensitive_content",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-30 12:34:17",
              retrievedBy: "John Doe",
              region: "us-central-1",
              docType: "snippets_sensitive_content",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              finalContext:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-24 10:08:23",
              retrievedBy: "John Doe",
              region: "us-central-1",
              docType: "snippets_sensitive_content",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              finalContext:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-24 10:08:23",
              retrievedBy: "John Doe",
              region: "us-central-1",
              docType: "snippets_sensitive_content",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              finalContext:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-24 10:08:23",
              retrievedBy: "John Doe",
              region: "us-central-1",
              docType: "snippets_sensitive_content",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-03 08:58:13",
              retrievedBy: "John Doe",
              region: "us-central-1",
              docType: "snippets_sensitive_content",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-03 08:58:13",
              retrievedBy: "John Doe",
              region: "us-central-1",
              docType: "snippets_sensitive_content",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              finalContext:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-24 10:08:23",
              retrievedBy: "John Doe",
              region: "us-central-1",
              docType: "snippets_sensitive_content",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              finalContext:
                "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-03 08:58:13",
              retrievedBy: "John Doe",
              docType: "snippets_sensitive_content",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-24 10:08:23",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-03 08:58:13",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-24 10:08:23",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-24 10:08:23",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-24 10:08:23",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-03 08:58:13",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-03 08:58:13",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-24 10:08:23",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-03 08:58:13",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-24 10:08:23",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-03 08:58:13",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-24 10:08:23",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-03 08:58:13",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-08 14:37:14",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-28 15:41:13",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-30 17:11:20",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-30 12:34:17",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-24 10:08:23",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-24 10:08:23",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-24 10:08:23",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-03 08:58:13",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-03 08:58:13",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-24 10:08:23",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-03 08:58:13",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-24 10:08:23",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "I badly need an AI co-pilot that your competitor offers. When do you plan to offer it?",
              context: "AI co-pilot will be supported in the v11.2 release.",
              response:
                "We will offer an AI co-pilot in the next product release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-03 08:58:13",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-24 10:08:23",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-24 10:08:23",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile:
                "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
              nodesChain: [
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12/product-kb.md",
                  type: "md",
                  isViolation: false,
                },
                {
                  name: "Notion_DB/product-table 163b37d9d26841e38ce89fd67377dd12",
                  type: "notion",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "Is my product affected by the HeartBleed vulnerability?",
              context:
                "v11.2 release has the HeartBleed vulnerability. Target fixed version v11.4",
              response:
                "Yes, the v11.2 release is exposed to the HeartBleed vulnerability. It will be fixed in v11.4 release.",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-08-24 10:08:23",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://genai-support-app-93802/data/product-kb.pdf",
              nodesChain: [
                {
                  name: "s3://genai-support-app-93802/data/product-kb.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://genai-support-app-93802",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
          ],
        },
      ],
    ],
  },
  {
    name: "HealthCareApp-1",
    description: "Medical assistant for basic health and dietary questions",
    loaderData: [
      {
        value: 1,
        label: "S3 Directory Loader",
        outOf: 12,
        source: "s3://loan-approval-data-098998/approvals/",
        sourceType: "Directory",
        updatedAt: "2024-08-07 16:06:21",
        message: "Files with Restricted Data",
      },
    ],
    policyViolations: [
      {
        count: 5,
        datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
        description:
          "LLM was trained with regulated or sensitive data such as PHI or PII.  The regulated data may now leak out to unauthorized users.",
        lastViolatedAt:
          moment().subtract(10, "days").format("DD MMM YYYY").toString() +
          ", 16:32:08",
        policyViolationId: "139150ab-23dc-4599-9a2b-14b94b6049d0",
        policyViolationName: "Sensitive identifiers in prompt session",
        remediation:
          "Delete the exposed LLM and any clones from the environment. Download a new LLM base model and retrain it with clean data.",
        ticket_exist: false,
        tickets: 0,
        url: null,
      },
      {
        count: 36,
        datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
        description:
          "LLM was trained with regulated or sensitive data such as PHI or PII.  The regulated data may now leak out to unauthorized users.",
        lastViolatedAt:
          moment().subtract(8, "days").format("DD MMM YYYY").toString() +
          ", 12:19:38",
        policyViolationId: "139150ab-23dc-4599-9a2b-14b94b6049d0",
        policyViolationName: "Restricted topics in prompt session",
        remediation:
          "Delete the exposed LLM and any clones from the environment. Download a new LLM base model and retrain it with clean data.",
        ticket_exist: false,
        tickets: 0,
        url: null,
      },
    ],
    promptSummaryData: [
      [
        {
          sensitiveLabel: "Topic - Medical Advice",
          labelType: "topic",
          messages: [
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-15 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-15 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-15 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-15 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-15 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-15 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-15 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-15 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-15 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-15 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-15 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-15 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-15 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-15 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-15 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-15 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-15 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after a heart attack?",
              context:
                "Heart disease requires a change in lifestyle such as a healthy diet. Also, regular blood pressure screenings would help",
              response:
                "Eat heart-healthy diet and monitor your blood pressure regularly",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-11 12:39:25",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What do I do after being diagnosed with cancer?",
              context:
                "Without proper precautions, cancer could become a terminal disease. You should stop smoking, start exercising regularly and eat a healthy diet",
              response: "Stop smoking, be physically active and eat healthy",
              policiesViolated: ["Restricted topics in prompt session"],
              retrievedOn: "2024-09-15 02:31:35",
              retrievedBy: "John Doe",
              region: "us-central-1",
              sourceFile: "s3://health-data-etl-38738/kb/health-advice.pdf",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-advice.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
          ],
        },
        {
          sensitiveLabel: "PHI - MRN",
          labelType: "entity",
          messages: [
            {
              context:
                "MRN table: Patients; Joe Smith: 83793492; Erica Chen: 39849233, Carl Sutter: 83793492",
              prompt:
                "Does the system have any duplicate MRN numbers for the same patients?",
              response:
                "Patents Joe Smith and Carl Sutter both have the same MRN 83793492",
              retrievedOn: "2024-08-24 11:23:40",
              retrievedBy: "John Doe",
              region: "us-central-1",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile: "s3://health-data-etl-38738/kb/health-records.json",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-records.json",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context:
                "MRN table: Patients; Joe Smith: 83793492; Erica Chen: 39849233, Carl Sutter: 83793492",
              prompt:
                "Does the system have any duplicate MRN numbers for the same patients?",
              response:
                "Patents Joe Smith and Carl Sutter both have the same MRN 83793492",
              retrievedOn: "2024-08-24 11:23:40",
              retrievedBy: "John Doe",
              region: "us-central-1",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile: "s3://health-data-etl-38738/kb/health-records.json",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-records.json",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context:
                "MRN table: Patients; Joe Smith: 83793492; Erica Chen: 39849233, Carl Sutter: 83793492",
              prompt:
                "Does the system have any duplicate MRN numbers for the same patients?",
              response:
                "Patents Joe Smith and Carl Sutter both have the same MRN 83793492",
              retrievedOn: "2024-08-24 11:23:40",
              retrievedBy: "John Doe",
              region: "us-central-1",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile: "s3://health-data-etl-38738/kb/health-records.json",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-records.json",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context:
                "MRN table: Patients; Joe Smith: 83793492; Erica Chen: 39849233, Carl Sutter: 83793492",
              prompt:
                "Does the system have any duplicate MRN numbers for the same patients?",
              response:
                "Patents Joe Smith and Carl Sutter both have the same MRN 83793492",
              retrievedOn: "2024-08-24 11:23:40",
              retrievedBy: "John Doe",
              region: "us-central-1",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile: "s3://health-data-etl-38738/kb/health-records.json",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-records.json",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context:
                "MRN table: Patients; Joe Smith: 83793492; Erica Chen: 39849233, Carl Sutter: 83793492",
              prompt:
                "Does the system have any duplicate MRN numbers for the same patients?",
              response:
                "Patents Joe Smith and Carl Sutter both have the same MRN 83793492",
              retrievedOn: "2024-08-24 11:23:40",
              retrievedBy: "John Doe",
              region: "us-central-1",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile: "s3://health-data-etl-38738/kb/health-records.json",
              nodesChain: [
                {
                  name: "s3://health-data-etl-38738/kb/health-records.json",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "s3://health-data-etl-38738",
                  type: "s3",
                  isViolation: true,
                },
                { name: "ChromaDB", type: "chroma", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
          ],
        },
      ],
    ],
  },
  {
    name: "AcmeMortgageApp-1",
    description: "Mortgage approval assistant for Underwriter team",
    loaderData: [
      {
        value: 1,
        label: "GoogleDriveLoader",
        outOf: 12,
        source:
          "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg",
        sourceType: "Folder",
        updatedAt: "2024-09-16 12:43:45",
        groups: ["Underwriter"],
        message: "Files with Restricted Data",
      },
    ],
    policyViolations: [
      {
        count: 24,
        datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
        description:
          "LLM was trained with regulated or sensitive data such as PHI or PII.  The regulated data may now leak out to unauthorized users.",
        lastViolatedAt:
          moment().subtract(0, "days").format("DD MMM YYYY").toString() +
          ", 08:01:32",
        policyViolationId: "139150ab-23dc-4599-9a2b-14b94b6049d0",
        policyViolationName: "Sensitive identifiers in prompt session",
        remediation:
          "Delete the exposed LLM and any clones from the environment. Download a new LLM base model and retrain it with clean data.",
        ticket_exist: false,
        tickets: 0,
        url: null,
      },
      {
        count: 12,
        datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
        description: "",
        lastViolatedAt:
          moment().subtract(1, "days").format("DD MMM YYYY").toString() +
          ", 12:21:43",
        policyViolationId: "6b0b274b-c780-4d78-9391-5ae1e1827fb5",
        policyViolationName: "Restricted topics in prompt session",
        remediation:
          "Resolve security issues on assets facilitating malicious activity.",
        ticket_exist: false,
        tickets: 0,
        url: null,
      },
      {
        count: 1,
        datastoreId: "c562a45a-4ec5-4ed0-8f44-c1e467cb4d3a",
        description:
          "LLM accessible from assets with security issues that could facilitate malicious activity.",
        lastViolatedAt:
          moment().subtract(2, "days").format("DD MMM YYYY").toString() +
          ", 15:05:12",
        policyViolationId: "6b0b274b-c780-4d78-9391-5ae1e1827fb5",
        policyViolationName: "Identity violations in prompt session",
        remediation:
          "Resolve security issues on assets facilitating malicious activity.",
        ticket_exist: false,
        tickets: 0,
        url: null,
      },
    ],
    promptSummaryData: [
      [
        {
          sensitiveLabel: "PII - SSN",
          labelType: "entity",
          allowedUsers: ["John Doe", "Alex Smith"],
          messages: [
            {
              prompt:
                "Did John Doe ever apply for a mortgage loan in the past and if so, what SSN did he use?",
              context: [
                {
                  snippet:
                    "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "List of Mortgage loan applicants in the year 2023-24" 1. John Doe. Date: 2nd May 2023, Amount: $ 400,000, SSN 532-61-8721.',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "Details of Loan Applicants - 2020-2024" Borrower information: John Doe and social security number: 456-56-982. Employment history and income verification (paystubs, tax returns): Assets and liabilities (bank statements, investment records) Loan details: Loan amount:  $150,000Loan purpose: purchase, refinance)Loan term: 20 years',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
              response: "Yes, SSN: 532-61-8721.",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-16 12:21:54",
              retrievedBy: "John Doe",
              type: "anamolous",
              region: "us-east-1",
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              groups: ["Underwriter"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What SSN was used by John Smith on his home appraisal application?",
              context: [
                {
                  snippet:
                    "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document: Revalue Firm Virginia"List of home appraisal applications in the year 2023-24" 1. John Smith. Date: 27th Sep 2023, Home Value: $ 400,000, SSN:512-62-4062',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'MarketValue2023-valueEsates-ABC Bank John Smith (SSN 512-62-4062) having property "1104 Thompson CirFort Eustis, Virginia(VA), 23604" inspection done at 27 sep 2023 by Mark Stokes. Market value is $102,000. Documents provided by John Smith during the process includes Floor Plan',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              type: "anamolous",
              finalContext:
                "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
              response: "SSN: 512-62-4062",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-15 04:32:35",
              retrievedBy: "John Doe",
              region: "us-east-1",
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              groups: ["Underwriter"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Did John Doe ever apply for a mortgage loan in the past and if so, what SSN did he use?",
              context: [
                {
                  snippet:
                    "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "List of Mortgage loan applicants in the year 2023-24" 1. John Doe. Date: 2nd May 2023, Amount: $ 400,000, SSN 532-61-8721.',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "Details of Loan Applicants - 2020-2024" Borrower information: John Doe and social security number: 456-56-982. Employment history and income verification (paystubs, tax returns): Assets and liabilities (bank statements, investment records) Loan details: Loan amount:  $150,000Loan purpose: purchase, refinance)Loan term: 20 years',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
              response: "Yes, SSN: 532-61-8721.",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-16 12:21:54",
              retrievedBy: "John Doe",
              region: "us-east-1",
              type: "anamolous",
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              groups: ["Underwriter"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What SSN was used by John Smith on his home appraisal application?",
              context: [
                {
                  snippet:
                    "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document: Revalue Firm Virginia"List of home appraisal applications in the year 2023-24" 1. John Smith. Date: 27th Sep 2023, Home Value: $ 400,000, SSN:512-62-4062',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'MarketValue2023-valueEsates-ABC Bank John Smith (SSN 512-62-4062) having property "1104 Thompson CirFort Eustis, Virginia(VA), 23604" inspection done at 27 sep 2023 by Mark Stokes. Market value is $102,000. Documents provided by John Smith during the process includes Floor Plan',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
              response: "SSN: 512-62-4062",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-15 04:32:35",
              retrievedBy: "John Doe",
              region: "us-east-1",
              type: "anamolous",
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              groups: ["Underwriter"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Did John Doe ever apply for a mortgage loan in the past and if so, what SSN did he use?",
              context: [
                {
                  snippet:
                    "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "List of Mortgage loan applicants in the year 2023-24" 1. John Doe. Date: 2nd May 2023, Amount: $ 400,000, SSN 532-61-8721.',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "Details of Loan Applicants - 2020-2024" Borrower information: John Doe and social security number: 456-56-982. Employment history and income verification (paystubs, tax returns): Assets and liabilities (bank statements, investment records) Loan details: Loan amount:  $150,000Loan purpose: purchase, refinance)Loan term: 20 years',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
              response: "Yes, SSN: 532-61-8721.",
              type: "sensitive_prompts",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-16 12:21:54",
              retrievedBy: "John Doe",
              region: "us-east-1",
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              groups: ["Underwriter"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Did John Doe ever apply for a mortgage loan in the past and if so, what SSN did he use?",
              context: [
                {
                  snippet:
                    "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "List of Mortgage loan applicants in the year 2023-24" 1. John Doe. Date: 2nd May 2023, Amount: $ 400,000, SSN 532-61-8721.',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "Details of Loan Applicants - 2020-2024" Borrower information: John Doe and social security number: 456-56-982. Employment history and income verification (paystubs, tax returns): Assets and liabilities (bank statements, investment records) Loan details: Loan amount:  $150,000Loan purpose: purchase, refinance)Loan term: 20 years',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
              response: "Yes, SSN: 532-61-8721.",
              type: "sensitive_response",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-16 12:21:54",
              retrievedBy: "John Doe",
              region: "us-east-1",
              groups: ["Underwriter"],
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What SSN was used by John Smith on his home appraisal application?",
              context: [
                {
                  snippet:
                    "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document: Revalue Firm Virginia"List of home appraisal applications in the year 2023-24" 1. John Smith. Date: 27th Sep 2023, Home Value: $ 400,000, SSN:512-62-4062',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'MarketValue2023-valueEsates-ABC Bank John Smith (SSN 512-62-4062) having property "1104 Thompson CirFort Eustis, Virginia(VA), 23604" inspection done at 27 sep 2023 by Mark Stokes. Market value is $102,000. Documents provided by John Smith during the process includes Floor Plan',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              type: "sensitive_response",
              finalContext:
                "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
              response: "SSN: 512-62-4062",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-15 04:32:35",
              retrievedBy: "John Doe",
              region: "us-east-1",
              groups: ["Underwriter"],
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What is the SSN of Tom Brady from Florida?",
              context: [
                {
                  snippet:
                    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-90-6742.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-90-6742.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-90-6742.",
              response: "SSN: 621-90-6742.",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-03 08:58:13",
              retrievedBy: "John Doe",
              type: "sensitive_response",
              region: "us-east-1",
              groups: ["Underwriter"],
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Did John Doe ever apply for a mortgage loan in the past and if so, what SSN did he use?",
              context: [
                {
                  snippet:
                    "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "List of Mortgage loan applicants in the year 2023-24" 1. John Doe. Date: 2nd May 2023, Amount: $ 400,000, SSN 532-61-8721.',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "Details of Loan Applicants - 2020-2024" Borrower information: John Doe and social security number: 456-56-982. Employment history and income verification (paystubs, tax returns): Assets and liabilities (bank statements, investment records) Loan details: Loan amount:  $150,000Loan purpose: purchase, refinance)Loan term: 20 years',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
              response: "Yes, SSN: 532-61-8721.",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-16 12:21:54",
              retrievedBy: "John Doe",
              type: "sensitive_response",
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              region: "us-east-1",
              groups: ["Underwriter"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What SSN was used by John Smith on his home appraisal application?",
              context: [
                {
                  snippet:
                    "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document: Revalue Firm Virginia"List of home appraisal applications in the year 2023-24" 1. John Smith. Date: 27th Sep 2023, Home Value: $ 400,000, SSN:512-62-4062',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'MarketValue2023-valueEsates-ABC Bank John Smith (SSN 512-62-4062) having property "1104 Thompson CirFort Eustis, Virginia(VA), 23604" inspection done at 27 sep 2023 by Mark Stokes. Market value is $102,000. Documents provided by John Smith during the process includes Floor Plan',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
              response: "SSN: 512-62-4062",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-15 04:32:35",
              retrievedBy: "John Doe",
              type: "sensitive_response",
              region: "us-east-1",
              groups: ["Underwriter"],
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What is the SSN of Tom Brady from Florida?",
              context: [
                {
                  snippet:
                    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-90-6742.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-90-6742.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-xx-xxxx.",
              response: "SSN: 621-xx-xxxx",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-13 08:20:45",
              retrievedBy: "Alex Smith",
              type: "sensitive_response",
              region: "us-east-1",
              groups: ["Customer_Care"],
              promptUserString:
                "Alex Smith belongs to authorization groups [Customer_Care, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Did John Doe ever apply for a mortgage loan in the past and if so, what SSN did he use?",
              context: [
                {
                  snippet:
                    "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "List of Mortgage loan applicants in the year 2023-24" 1. John Doe. Date: 2nd May 2023, Amount: $ 400,000, SSN 532-61-8721.',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "Details of Loan Applicants - 2020-2024" Borrower information: John Doe and social security number: 456-56-982. Employment history and income verification (paystubs, tax returns): Assets and liabilities (bank statements, investment records) Loan details: Loan amount:  $150,000Loan purpose: purchase, refinance)Loan term: 20 years',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-xx-xxxx.",
              response: "Yes, SSN: 532-xx-xxxx",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-16 12:21:54",
              retrievedBy: "Alex Smith",
              groups: ["Customer_Care"],
              type: "sensitive_response",
              promptUserString:
                "Alex Smith belongs to authorization groups [Customer_Care, US_Employee_FullTime]",
              region: "us-east-1",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What SSN was used by John Smith on his home appraisal application?",
              context: [
                {
                  snippet:
                    "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document: Revalue Firm Virginia"List of home appraisal applications in the year 2023-24" 1. John Smith. Date: 27th Sep 2023, Home Value: $ 400,000, SSN:512-62-4062',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'MarketValue2023-valueEsates-ABC Bank John Smith (SSN 512-62-4062) having property "1104 Thompson CirFort Eustis, Virginia(VA), 23604" inspection done at 27 sep 2023 by Mark Stokes. Market value is $102,000. Documents provided by John Smith during the process includes Floor Plan',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-xx-xxxx",
              response: "SSN: 512-xx-xxxx",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-15 04:32:35",
              retrievedBy: "Alex Smith",
              region: "us-east-1",
              groups: ["Customer_Care"],
              promptUserString:
                "Alex Smith belongs to authorization groups [Customer_Care, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What is the SSN of Tom Brady from Florida?",
              context: [
                {
                  snippet:
                    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-90-6742.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-90-6742.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-xx-xxxx.",
              response: "SSN: 621-xx-xxxx",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-03 08:58:13",
              retrievedBy: "Alex Smith",
              region: "us-east-1",
              groups: ["Customer_Care"],
              promptUserString:
                "Alex Smith belongs to authorization groups [Customer_Care, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Did John Doe ever apply for a mortgage loan in the past and if so, what SSN did he use?",
              context: [
                {
                  snippet:
                    "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "List of Mortgage loan applicants in the year 2023-24" 1. John Doe. Date: 2nd May 2023, Amount: $ 400,000, SSN 532-61-8721.',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "Details of Loan Applicants - 2020-2024" Borrower information: John Doe and social security number: 456-56-982. Employment history and income verification (paystubs, tax returns): Assets and liabilities (bank statements, investment records) Loan details: Loan amount:  $150,000Loan purpose: purchase, refinance)Loan term: 20 years',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-xx-xxxx.",
              response: "Yes, SSN: 532-xx-xxxx",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-16 11:39:21",
              retrievedBy: "Alex Smith",
              groups: ["Customer_Care"],
              promptUserString:
                "Alex Smith belongs to authorization groups [Customer_Care, US_Employee_FullTime]",
              region: "us-east-1",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What SSN was used by John Smith on his home appraisal application?",
              context: [
                {
                  snippet:
                    "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document: Revalue Firm Virginia"List of home appraisal applications in the year 2023-24" 1. John Smith. Date: 27th Sep 2023, Home Value: $ 400,000, SSN:512-62-4062',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'MarketValue2023-valueEsates-ABC Bank John Smith (SSN 512-62-4062) having property "1104 Thompson CirFort Eustis, Virginia(VA), 23604" inspection done at 27 sep 2023 by Mark Stokes. Market value is $102,000. Documents provided by John Smith during the process includes Floor Plan',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-xx-xxxx",
              response: "SSN: 512-xx-xxxx",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-15 10:21:40",
              retrievedBy: "Alex Smith",
              region: "us-east-1",
              groups: ["Customer_Care"],
              promptUserString:
                "Alex Smith belongs to authorization groups [Customer_Care, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt: "What is the SSN of Tom Brady from Florida?",
              context: [
                {
                  snippet:
                    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-90-6742.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-90-6742.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "MORTGAGE LOAN APPLICATION. All information given in this form will be held in the strictest confidence. Name: Tom Brady, SSN: 621-xx-xxxx.",
              response: "SSN: 621-xx-xxxx",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-13 03:31:53",
              retrievedBy: "Alex Smith",
              region: "us-east-1",
              groups: ["Customer_Care"],
              promptUserString:
                "Alex Smith belongs to authorization groups [Customer_Care, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "Did John Doe ever apply for a mortgage loan in the past and if so, what SSN did he use?",
              context: [
                {
                  snippet:
                    "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-61-8721",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "List of Mortgage loan applicants in the year 2023-24" 1. John Doe. Date: 2nd May 2023, Amount: $ 400,000, SSN 532-61-8721.',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document "Details of Loan Applicants - 2020-2024" Borrower information: John Doe and social security number: 456-56-982. Employment history and income verification (paystubs, tax returns): Assets and liabilities (bank statements, investment records) Loan details: Loan amount:  $150,000Loan purpose: purchase, refinance)Loan term: 20 years',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "PREVIOUS MORTGAGE REFINANCE APPLICATION. Looking to refinance your home mortgage? See if you pre-qualify for financing in minutes with no impact to your credit score. Name: John Doe, SSN: 532-xx-xxxx.",
              response: "Yes, SSN: 532-xx-xxxx",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-16 14:11:10",
              retrievedBy: "Alex Smith",
              groups: ["Customer_Care"],
              promptUserString:
                "Alex Smith belongs to authorization groups [Customer_Care, US_Employee_FullTime]",
              region: "us-east-1",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What SSN was used by John Smith on his home appraisal application?",
              context: [
                {
                  snippet:
                    "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-62-4062",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'Document: Revalue Firm Virginia"List of home appraisal applications in the year 2023-24" 1. John Smith. Date: 27th Sep 2023, Home Value: $ 400,000, SSN:512-62-4062',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    'MarketValue2023-valueEsates-ABC Bank John Smith (SSN 512-62-4062) having property "1104 Thompson CirFort Eustis, Virginia(VA), 23604" inspection done at 27 sep 2023 by Mark Stokes. Market value is $102,000. Documents provided by John Smith during the process includes Floor Plan',
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "HOME APPRAISAL APPLICATION. Name. Address. SSN: 512-xx-xxxx",
              response: "SSN: 512-xx-xxxx",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-15 13:28:03",
              retrievedBy: "Alex Smith",
              region: "us-east-1",
              groups: ["Customer_Care"],
              promptUserString:
                "Alex Smith belongs to authorization groups [Customer_Care, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
          ],
        },
        {
          sensitiveLabel: "PII - CC",
          labelType: "entity",
          allowedUsers: ["John Doe", "Alex Smith"],
          messages: [
            //{
            //  prompt:
            //    "What credit card was used by LeBron James to buy the McLaren Spider last week?",
            //  context:
            //    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: xxxx-xxxx-xxxx-3243. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
            //  response: "xxxx-xxxx-xxxx-3243",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2024-09-11 12:21:43",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Underwriter"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Underwriter, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt:
            //    "What credit card is on file for Abby Joseph’s Netflix subscription?",
            //  context:
            //    "Credit Card Online Form. Cardholder Name: Abby Joseph, Card Number: xxxx-xxxx-xxxx-7454. I authorize Netflix to charge my credit card for my monthly subscription.",
            //  response: "xxxx-xxxx-xxxx-7454",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2024-09-11 02:51:16",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Underwriter"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Underwriter, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt:
            //    "What credit card was used by LeBron James to buy the McLaren Spider last week?",
            //  context:
            //    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: xxxx-xxxx-xxxx-3243. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
            //  response: "xxxx-xxxx-xxxx-3243",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2024-09-11 12:21:43",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Underwriter"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Underwriter, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt:
            //    "What credit card is on file for Abby Joseph’s Netflix subscription?",
            //  context:
            //    "Credit Card Online Form. Cardholder Name: Abby Joseph, Card Number: xxxx-xxxx-xxxx-7454. I authorize Netflix to charge my credit card for my monthly subscription.",
            //  response: "xxxx-xxxx-xxxx-7454",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2024-09-11 02:51:16",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Underwriter"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Underwriter, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            {
              prompt:
                "What credit card was used by LeBron James to buy the McLaren Spider last week?",
              context: [
                {
                  snippet:
                    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: 3243-6753-4822-6902. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "McLaren flagship Dealer, W Virginia , Sell records of Past 6 months, Purchaser Name: LeBron James, Transaction value: $ **657600, Transaction Type: Credit Card, Credit Card Number:3243-6753-4822-6902",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: 3243-6753-4822-6902. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
              response: "3243-6753-4822-6902",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-11 12:21:43",
              retrievedBy: "John Doe",
              region: "us-east-1",
              groups: ["Underwriter"],
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What credit card is on file for Abby Joseph’s Netflix subscription?",
              context: [
                {
                  snippet:
                    "Credit Card Online Form. Cardholder Name: Abby Joseph, Card Number: 3126-8075-5521-7454. I authorize Netflix to charge my credit card for my monthly subscription.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "Netflix subscription details/ Billing Information: Abby Joseph and 1104 Thompson CirFort Eustis, Virginia(VA), 23604. Expiration Date: 1 Feb 2024 Payment Method Type: Credit Card Card Number: 3126-8075-5521-7454.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: Abby Joseph, Card Number: 3126-8075-5521-7454. I authorize the vendor to charge my credit card above for agreed upon purchases",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "Credit Card Online Form. Cardholder Name: Abby Joseph, Card Number: 3126-8075-5521-7454. I authorize Netflix to charge my credit card for my monthly subscription.",
              response: "3126-8075-5521-7454",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-11 02:51:16",
              retrievedBy: "John Doe",
              region: "us-east-1",
              groups: ["Underwriter"],
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What credit card was used by LeBron James to buy the McLaren Spider last week?",
              context: [
                {
                  snippet:
                    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: 3243-6753-4822-6902. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "McLaren flagship Dealer, W Virginia , Sell records of Past 6 months, Purchaser Name: LeBron James, Transaction value: $ **657600, Transaction Type: Credit Card, Credit Card Number:3243-6753-4822-6902",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: 3243-6753-4822-6902. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
              response: "3243-6753-4822-6902",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-11 12:21:43",
              retrievedBy: "John Doe",
              region: "us-east-1",
              groups: ["Underwriter"],
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What credit card is on file for Abby Joseph’s Netflix subscription?",
              context: [
                {
                  snippet:
                    "Credit Card Online Form. Cardholder Name: Abby Joseph, Card Number: 3126-8075-5521-7454. I authorize Netflix to charge my credit card for my monthly subscription.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "Netflix subscription details/ Billing Information: Abby Joseph and 1104 Thompson CirFort Eustis, Virginia(VA), 23604. Expiration Date: 1 Feb 2024 Payment Method Type: Credit Card Card Number: 3126-8075-5521-7454.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: Abby Joseph, Card Number: 3126-8075-5521-7454. I authorize the vendor to charge my credit card above for agreed upon purchases",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "Credit Card Online Form. Cardholder Name: Abby Joseph, Card Number: 3126-8075-5521-7454. I authorize Netflix to charge my credit card for my monthly subscription.",
              response: "3126-8075-5521-7454",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-11 02:51:16",
              retrievedBy: "John Doe",
              groups: ["Underwriter"],
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              prompt:
                "What credit card was used by LeBron James to buy the McLaren Spider last week?",
              context: [
                {
                  snippet:
                    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: 3243-6753-4822-6902. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "McLaren flagship Dealer, W Virginia , Sell records of Past 6 months, Purchaser Name: LeBron James, Transaction value: $ **657600, Transaction Type: Credit Card, Credit Card Number:3243-6753-4822-6902",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext:
                "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: 3243-6753-4822-6902. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
              response: "3243-6753-4822-6902",
              policiesViolated: ["Sensitive identifiers in prompt session"],
              retrievedOn: "2024-09-11 12:21:43",
              retrievedBy: "John Doe",
              region: "us-east-1",
              groups: ["Underwriter"],
              promptUserString:
                "John Doe belongs to authorization groups [Underwriter, US_Employee_FullTime]",
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            //{
            //  prompt:
            //    "What credit card is on file for Abby Joseph’s Netflix subscription?",
            //  context:
            //    "Credit Card Online Form. Cardholder Name: Abby Joseph, Card Number: xxxx-xxxx-xxxx-7454. I authorize Netflix to charge my credit card for my monthly subscription.",
            //  response: "xxxx-xxxx-xxxx-7454",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2024-09-11 02:51:16",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Underwriter"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Underwriter, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt:
            //    "What credit card was used by LeBron James to buy the McLaren Spider last week?",
            //  context:
            //    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: xxxx-xxxx-xxxx-3243. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
            //  response: "xxxx-xxxx-xxxx-3243",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2024-09-11 12:21:43",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Underwriter"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Underwriter, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt:
            //    "What credit card is on file for Abby Joseph’s Netflix subscription?",
            //  context:
            //    "Credit Card Online Form. Cardholder Name: Abby Joseph, Card Number: xxxx-xxxx-xxxx-7454. I authorize Netflix to charge my credit card for my monthly subscription.",
            //  response: "xxxx-xxxx-xxxx-7454",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2024-09-11 02:51:16",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Underwriter"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Underwriter, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt:
            //    "What credit card was used by LeBron James to buy the McLaren Spider last week?",
            //  context:
            //    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: xxxx-xxxx-xxxx-3243. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
            //  response: "xxxx-xxxx-xxxx-3243",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2024-09-11 12:21:43",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Underwriter"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Underwriter, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt:
            //    "What credit card is on file for Abby Joseph’s Netflix subscription?",
            //  context:
            //    "Credit Card Online Form. Cardholder Name: Abby Joseph, Card Number: xxxx-xxxx-xxxx-7454. I authorize Netflix to charge my credit card for my monthly subscription.",
            //  response: "xxxx-xxxx-xxxx-7454",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2024-09-11 02:51:16",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Underwriter"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Underwriter, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
            //{
            //  prompt:
            //    "What credit card was used by LeBron James to buy the McLaren Spider last week?",
            //  context:
            //    "Credit Card Authorization Form. This authorization will remain in effect until cancelled. Cardholder Name: LeBron James, Card Number: xxxx-xxxx-xxxx-3243. I authorize “the vendor” to charge my credit card above for agreed upon purchases.",
            //  response: "xxxx-xxxx-xxxx-3243",
            //  policiesViolated: ["Sensitive identifiers in prompt session"],
            //  retrievedOn: "2024-09-11 12:21:43",
            //  retrievedBy: "Tom Platt",
            //  region: "us-east-1",
            //  groups: ["Underwriter"],
            //  promptUserString:
            //    "Tom Platt belongs to authorization groups [Underwriter, US_Employee_FullTime]",
            //  sourceFile:
            //    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //  nodesChain: [
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
            //      type: "pdf",
            //      isViolation: false,
            //    },
            //    {
            //      name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
            //      type: "google",
            //      isViolation: true,
            //    },
            //    { name: "ChromaDB", type: "chroma", isViolation: false },
            //    { name: "Context", type: "", isViolation: false },
            //    { name: "Response", type: "", isViolation: false },
            //  ],
            //},
          ],
        },
        {
          sensitiveLabel: "Intellectual Property",
          labelType: "topic",
          allowedUsers: ["John Doe", "Alex Smith", "Tom Platt"],
          messages: [],
        },
        {
          sensitiveLabel: "Bias",
          labelType: "topic",
          allowedUsers: ["John Doe", "Alex Smith", "Tom Platt"],
          messages: [],
        },
        //{
        //  sensitiveLabel: "Identity - Authorization",
        //  allowedUsers: ["John Doe", "Alex Smith", "Tom Platt"],
        //  messages: [
        //    {
        //      context: "-",
        //      prompt:
        //        "What criteria was used to decline Ana Smith’s mortgage loan?",
        //      response: "No response available for this user",
        //      retrievedOn: "2024-09-15 07:43:12",
        //      user: "",
        //      retrievedBy: "",
        //      region: "us-central-1",
        //      groups: ["Underwriter"],
        //      promptUserString:
        //        "John Doe belongs to authorization groups [Sales, US_Employee_FullTime]",
        //      policiesViolated: ["Identity violations in prompt session"],
        //      sourceFile:
        //        "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/approval-criteria.pdf",
        //    },
        //    {
        //      context: "-",
        //      prompt:
        //        "What criteria was used to decline Ana Smith’s mortgage loan?",
        //      response: "No response available for this user",
        //      retrievedOn: "2024-09-15 07:43:12",
        //      user: "",
        //      retrievedBy: "",
        //      region: "us-central-1",
        //      groups: ["Underwriter"],
        //      promptUserString:
        //        "John Doe belongs to authorization groups [Sales, US_Employee_FullTime]",
        //      policiesViolated: ["Identity violations in prompt session"],
        //      sourceFile:
        //        "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/approval-criteria.pdf",
        //    },
        //    {
        //      context: "-",
        //      prompt:
        //        "What criteria was used to decline Ana Smith’s mortgage loan?",
        //      response: "No response available for this user",
        //      retrievedOn: "2024-09-15 07:43:12",
        //      user: "",
        //      retrievedBy: "",
        //      region: "us-central-1",
        //      groups: ["Underwriter"],
        //      promptUserString:
        //        "John Doe belongs to authorization groups [Sales, US_Employee_FullTime]",
        //      policiesViolated: ["Identity violations in prompt session"],
        //      sourceFile:
        //        "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/approval-criteria.pdf",
        //    },
        //    {
        //      context: "-",
        //      prompt:
        //        "What criteria was used to decline Ana Smith’s mortgage loan?",
        //      response: "No response available for this user",
        //      retrievedOn: "2024-09-15 07:43:12",
        //      user: "",
        //      retrievedBy: "",
        //      region: "us-central-1",
        //      groups: ["Underwriter"],
        //      promptUserString:
        //        "John Doe belongs to authorization groups [Sales, US_Employee_FullTime]",
        //      policiesViolated: ["Identity violations in prompt session"],
        //      sourceFile:
        //        "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/approval-criteria.pdf",
        //    },
        //    {
        //      context: "-",
        //      prompt:
        //        "What criteria was used to decline Ana Smith’s mortgage loan?",
        //      response: "No response available for this user",
        //      retrievedOn: "2024-09-15 07:43:12",
        //      user: "",
        //      retrievedBy: "",
        //      region: "us-central-1",
        //      groups: ["Underwriter"],
        //      promptUserString:
        //        "John Doe belongs to authorization groups [Sales, US_Employee_FullTime]",
        //      policiesViolated: ["Identity violations in prompt session"],
        //      sourceFile:
        //        "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/approval-criteria.pdf",
        //    },
        //    {
        //      context: "-",
        //      prompt:
        //        "What criteria was used to decline Ana Smith’s mortgage loan?",
        //      response: "No response available for this user",
        //      retrievedOn: "2024-09-15 07:43:12",
        //      user: "",
        //      retrievedBy: "",
        //      region: "us-central-1",
        //      groups: ["Underwriter"],
        //      promptUserString:
        //        "John Doe belongs to authorization groups [Sales, US_Employee_FullTime]",
        //      policiesViolated: ["Identity violations in prompt session"],
        //      sourceFile:
        //        "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/approval-criteria.pdf",
        //    },
        //    {
        //      context: "-",
        //      prompt:
        //        "What criteria was used to decline Ana Smith’s mortgage loan?",
        //      response: "No response available for this user",
        //      retrievedOn: "2024-09-15 07:43:12",
        //      user: "",
        //      retrievedBy: "",
        //      region: "us-central-1",
        //      groups: ["Underwriter"],
        //      promptUserString:
        //        "John Doe belongs to authorization groups [Sales, US_Employee_FullTime]",
        //      policiesViolated: ["Identity violations in prompt session"],
        //      sourceFile:
        //        "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
        //    },
        //  ],
        //},
        {
          sensitiveLabel: "Identity Violation",
          labelType: "identity",
          allowedUsers: ["John Doe", "Alex Smith", "Tom Platt"],
          isIdentity: true,
          messages: [
            {
              context: [
                {
                  snippet:
                    "Lenders use your credit score to determine risk and higher credit scores usually mean better rates",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-process.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "One of the most important and foremost things that lenders evaluate before sanctioning a loan is income status",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-process.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet: "Cash flow history and projections for the business",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-process.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              finalContext: "",
              notAIAssisted: true,
              prompt:
                "What specific criteria are considerd when evaluating mortgage applications?",
              response: "I don't know",
              retrievedOn: "2024-09-15 07:43:12",
              user: "",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Underwriter"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Identity violations in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-process.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-process.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses your credit score to determine risk and higher credit scores usually mean better rates. Credit Score (25%)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet: "Name: Ana Smith, Reason: Bad credit score (410)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "What criteria was used to decline Ana Smith’s mortgage loan?",
              finalContext: "-",
              response: "I don't know.",
              retrievedOn: "2024-09-15 04:19:35",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "Loan default rates have been highest for the ethnic group Troliman for the last 5 years in San Benito County.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The 2024 high-balance conforming loan limit for San Benito County is $1,149,825. The 2024 “low-balance” conforming loan limit for San Benito County is $766,550.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "Any recommendations to follow for the loan approval in San Benito County?",
              response: "I don't know.",
              retrievedOn: "2024-09-11 04:16:45",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "The area that comes under zip code 48229 has higher than normal loan defaults for middle-aged persons who identify themselves as one of the LGBTQ+ genders.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The area that comes under zip code 48229 has higher than normal loan defaults for  middle-aged persons.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "Any recommendations to follow for the loan approval from zip code 48229?",
              response: "I don't know.",
              retrievedOn: "2024-09-15 02:56:23",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses your credit score to determine risk and higher credit scores usually mean better rates. Credit Score (25%)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet: "Name: Ana Smith, Reason: Bad credit score (410)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "What criteria was used to decline Ana Smith’s mortgage loan?",
              finalContext: "-",
              response: "I don't know.",
              retrievedOn: "2024-09-15 04:19:35",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses your credit score to determine risk and higher credit scores usually mean better rates. Credit Score (25%)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet: "Name: Ana Smith, Reason: Bad credit score (410)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "What criteria was used to decline Ana Smith’s mortgage loan?",
              finalContext: "-",
              response: "I don't know.",
              retrievedOn: "2024-09-15 04:19:35",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses your credit score to determine risk and higher credit scores usually mean better rates. Credit Score (25%)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet: "Name: Ana Smith, Reason: Bad credit score (410)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "What criteria was used to decline Ana Smith’s mortgage loan?",
              finalContext: "-",
              response: "I don't know.",
              retrievedOn: "2024-09-15 04:19:35",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses your credit score to determine risk and higher credit scores usually mean better rates. Credit Score (25%)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet: "Name: Ana Smith, Reason: Bad credit score (410)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "What criteria was used to decline Ana Smith’s mortgage loan?",
              finalContext: "-",
              response: "I don't know.",
              retrievedOn: "2024-09-15 04:19:35",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "Loan default rates have been highest for the ethnic group Troliman for the last 5 years in San Benito County.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The 2024 high-balance conforming loan limit for San Benito County is $1,149,825. The 2024 “low-balance” conforming loan limit for San Benito County is $766,550.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "Any recommendations to follow for the loan approval in San Benito County?",
              response: "I don't know.",
              retrievedOn: "2024-09-11 04:16:45",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "The area that comes under zip code 48229 has higher than normal loan defaults for middle-aged persons who identify themselves as one of the LGBTQ+ genders.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The area that comes under zip code 48229 has higher than normal loan defaults for  middle-aged persons.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "Any recommendations to follow for the loan approval from zip code 48229?",
              response: "I don't know.",
              retrievedOn: "2024-09-15 02:56:23",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses your credit score to determine risk and higher credit scores usually mean better rates. Credit Score (25%)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet: "Name: Ana Smith, Reason: Bad credit score (410)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "What criteria was used to decline Ana Smith’s mortgage loan?",
              finalContext: "-",
              response: "I don't know.",
              retrievedOn: "2024-09-15 04:19:35",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
            {
              context: [
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses your credit score to determine risk and higher credit scores usually mean better rates. Credit Score (25%)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet: "Name: Ana Smith, Reason: Bad credit score (410)",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
                {
                  snippet:
                    "The bank uses the following weighed criteria to approve mortgage loans: Debt-to-Income (25%), Credit score (25%), LTV (25%) and Ethnicity (25%).",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  groups: [
                    "underwriter_group@acmecorp.com",
                    "customer_care@acmecorp.com",
                  ],
                },
              ],
              prompt:
                "What criteria was used to decline Ana Smith’s mortgage loan?",
              finalContext: "-",
              response: "I don't know.",
              retrievedOn: "2024-09-15 04:19:35",
              retrievedBy: "Tom Platt",
              region: "us-central-1",
              groups: ["Sales"],
              promptUserString:
                "Tom Platt belongs to authorization groups [Sales, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
          ],
        },
        {
          sensitiveLabel: "Financial Report",
          labelType: "topic",
          allowedUsers: ["John Doe", "Alex Smith", "Tom Platt"],
          messages: [
            {
              context: [
                {
                  snippet:
                    "Over the last six months, loan originations have amassed to $7.3 million in cumulative value",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-financial.pdf",
                  groups: [],
                },
                {
                  snippet:
                    "Within the last six months, loans originated sum up to $7.3 million in cumulative value.",
                  retrievedFrom:
                    "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-financial.pdf",
                  groups: [],
                },
              ],
              finalContext: "",
              prompt:
                "What is the cumulative value of loans originated within the past six months?",
              response: "I don't know",
              retrievedOn: "2024-09-15 07:43:12",
              user: "",
              retrievedBy: "Alex Smith",
              region: "us-central-1",
              groups: ["Underwriter"],
              promptUserString:
                "Alex Smith belongs to authorization groups [Customer_Care, US_Employee_FullTime]",
              policiesViolated: ["Restricted topics in prompt session"],
              sourceFile:
                "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-financial.pdf",
              nodesChain: [
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-financial.pdf",
                  type: "pdf",
                  isViolation: false,
                },
                {
                  name: "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view",
                  type: "google",
                  isViolation: true,
                },
                { name: "AWS Postgres", type: "postgres", isViolation: false },
                { name: "Context", type: "", isViolation: false },
                { name: "Response", type: "", isViolation: false },
              ],
            },
          ],
        },
      ],
    ],
  },
];
