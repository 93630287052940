import { useTheme } from "@mui/material";
import { capitalizeFirstLetter, getIcon } from "../../utils/commonUtils";
import Box from "../common/Box";
import CardWithAction from "../common/CardWithAction";
import Typography from "../common/Typography";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/appContext";
import { sensitiveInfoTags } from "../constants/constants";
import {
  modelData,
  totalPolicyViolationCount,
} from "../../mock-data/frameworkData";
import gcpIcon from "../../assets/img/gcp.svg";
import awsIcon from "../../assets/img/awsIcon.svg";
import Loader from "../common/Loader";
import { llms } from "../../mock-data/llms";

const LLMCard = () => {
  const theme = useTheme();
  const [models, setModels] = useState<any>([]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const { getAIDatastores, dashboardViewLense, dataStoreViolation } =
    useContext(AppContext);
  const styles = {
    listing: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      paddingX: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      height: "400px",
      overflow: "auto",
    },
    card: {
      display: "flex",
      gap: theme.spacing(1),
      paddingY: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      backgroundColor: "rgba(49, 53, 74, 0.5)",
      borderRadius: "4px",
      cursor: "pointer",
    },
    details: {
      display: "flex",
      gap: theme.spacing(2),
    },
    keyValue: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    flexCol: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
  };

  const getSensitiveDataTags = (sensitiveInfo) => {
    let sensitiveTag = "";
    return sensitiveTag?.concat(
      sensitiveInfo?.map(
        (sensitiveData) => sensitiveInfoTags[sensitiveData?.name]
      )
    );
  };

  const navigateToLLM = (datastoreId, assetId) => {
    if (datastoreId && assetId) {
      history.push(`/llm/${datastoreId}/${assetId.replace("/", "_")}`);
    }
  };

  const modelStatus = (modelName) => {
    const model = dataStoreViolation[1]?.data?.find(
      (model) => model.name === modelName
    );
    return model ? "Deployed" : "Under Finetuning";
  };

  const getModelObject = (assetId) => {
    return modelData.find((model) => model.assetId === assetId);
  };

  useEffect(() => {
    setModels(llms);
    setIsLoading(false);
  }, [dataStoreViolation]);

  return (
    <CardWithAction
      title="LLMs"
      children={
        <Box sx={styles.listing}>
          {!isLoading ? (
            models.map((model: any) => {
              const modelInfo = getModelObject(model?.assetId);
              console.log(model);
              return (
                <Box
                  sx={styles.card}
                  onClick={() =>
                    navigateToLLM(model?.datastoreId, model?.assetId)
                  }
                >
                  <img
                    src={getIcon(model?.cloud || "aws")}
                    alt="source"
                    height={16}
                  />
                  <Box sx={styles.keyValue}>
                    <Typography
                      variant="body2"
                      color={theme.palette.surface60.main}
                    >
                      {model?.name}
                    </Typography>
                    <Box sx={styles.details}>
                      <Box sx={styles.keyValue}>
                        <Typography
                          variant="caption"
                          color={theme.palette.surface30.main}
                        >
                          Status
                        </Typography>
                        <Typography
                          variant="caption"
                          color={theme.palette.surface60.main}
                        >
                          {model?.status || modelStatus(model?.name || "")}
                        </Typography>
                      </Box>
                      <Box sx={styles.keyValue}>
                        <Typography
                          variant="caption"
                          color={theme.palette.surface30.main}
                        >
                          Category
                        </Typography>
                        <Typography
                          variant="caption"
                          color={theme.palette.surface60.main}
                        >
                          {capitalizeFirstLetter(model?.datastoreType)}
                        </Typography>
                      </Box>
                      <Box sx={styles.keyValue}>
                        <Typography
                          variant="caption"
                          color={theme.palette.surface30.main}
                        >
                          Labels
                        </Typography>
                        <Typography
                          variant="caption"
                          color={theme.palette.surface60.main}
                        >
                          {getSensitiveDataTags(model?.sensitiveInfo) || "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Loader />
          )}
        </Box>
      }
    />
  );
};

export default LLMCard;
