import moment from "moment";

export const classificationLabelsList = [
  {
    id: "1",
    name: "C1",
    addedOn: moment().subtract(1, "days").format("YYYY-MM-DD | HH:mm:ss"),
    docsTagged: 103,
  },
  {
    id: "2",
    name: "C2",
    addedOn: moment().format("YYYY-MM-DD | HH:mm:ss"),
    docsTagged: 56,
  },
  {
    id: "3",
    name: "C3",
    addedOn: moment().format("YYYY-MM-DD | HH:mm:ss"),
    docsTagged: 213,
  },
  {
    id: "4",
    name: "C4",
    addedOn: moment().format("YYYY-MM-DD | HH:mm:ss"),
    docsTagged: 112,
  },
];
