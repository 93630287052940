export const retrievalUserDetails = [
  {
    name: "Alex Smith",
    designation: "US Intern",
    groups: ["us_intern_group@acmecorp.com"],
    apps: ["AcmeMortgageApp-1", "SupportApp-1"],
    retrievals: [
      {
        value: 9,
        label: "PII - SSN",
        color: "#BF87D6",
      },
      {
        value: 13,
        label: "AWS Access Key",
        color: "#85BDED",
      },
      {
        value: 1,
        label: "Financial Report",
        color: "#F2B485",
      },
    ],
    frameworkRestrictedLabels: {
      ProductInsights: ["Board Meeting Notes", "Consulting Agreement"],
      "AcmeMortgageApp-1": [
        "PII - SSN",
        "PII - CC",
        "Intellectual Property",
        "Bias",
        "Financial Report",
      ],
      "HealthCareApp-1": ["Symmetric Key"],
      "SupportApp-1": ["PII - SSN", "AWS Access Key"],
    },
    prompts: 24,
    anomolous: 12,
    sensitiveResponse: {
      value: 14,
      outOf: 24,
      anamolous: 12,
    },
    sensitivePrompts: {
      value: 5,
      outOf: 20,
      anamolous: 0,
    },
    aiInsight:
      "Alex Smith has been accessing higher amounts of AWS Access Key data recently. Create a ticket to restrict access.",
  },
  {
    name: "John Doe",
    designation: "Support Admin",
    groups: ["us_support_group@acmecorp.com"],
    apps: ["AcmeMortgageApp-1", "SupportApp-1"],
    retrievals: [
      {
        value: 41,
        label: "Finance",
        color: "#BF87D6",
      },
      {
        value: 32,
        label: "Sales",
        color: "#F2B485",
      },
      {
        value: 23,
        label: "HR",
        color: "#85BDED",
      },
      {
        value: 23,
        label: "Health",
        color: "#A76171",
      },
      {
        value: 23,
        label: "Company Data",
        color: "#ACCA9F",
      },
    ],
    prompts: 102,
    frameworkRestrictedLabels: {
      ProductInsights: ["Board Meeting Notes", "Consulting Agreement"],
      "AcmeMortgageApp-1": [],
      "HealthCareApp-1": ["Symmetric Key"],
      "SupportApp-1": ["PII - SSN", "Consulting Agreement"],
    },
    sensitiveResponse: {
      value: 0,
      outOf: 38,
      anamolous: 0,
    },
    sensitivePrompts: {
      value: 5,
      outOf: 36,
      anamolous: 0,
    },
  },
  {
    name: "Tom Platt",
    designation: "Customer Care",
    groups: ["customer_care@acmecorp.com"],
    apps: ["AcmeMortgageApp-1"],
    frameworkRestrictedLabels: {
      ProductInsights: ["Board Meeting Notes", "Consulting Agreement"],
      "AcmeMortgageApp-1": [
        "Intellectual Property",
        "Bias",
        "Sexual Harassment",
        "Client Secret",
        "Merger Agreement",
      ],
      "HealthCareApp-1": ["Symmetric Key"],
      "SupportApp-1": ["PII - SSN"],
    },
    prompts: 17,
    sensitiveResponse: {
      value: 0,
      outOf: 36,
      anamolous: 0,
    },
    sensitivePrompts: {
      value: 14,
      outOf: 45,
      anamolous: 0,
    },
  },
];

export const fileDetails = [
  {
    id: "1",
    fileName:
      "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/historical-loans.pdf",
    authorizedGroups: [
      "underwriter_group@acmecorp.com",
      "customer_care@acmecorp.com",
    ],
    accessCount: 35,
    owner: "John Doe",
  },
  {
    id: "1",
    fileName:
      "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-process.pdf",
    authorizedGroups: [
      "underwriter_group@acmecorp.com",
      "customer_care@acmecorp.com",
    ],
    accessCount: 1,
    owner: "John Doe",
  },
  {
    id: "1",
    fileName:
      "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-financial.pdf",
    authorizedGroups: [],
    accessCount: 1,
    owner: "John Doe",
  },
  {
    id: "1",
    fileName:
      "https://acme-corp.sharepoint.com/support-portal/documents/support-kb.pdf",

    authorizedGroups: ["us_support_group@acmecorp.com"],
    accessCount: 7,
    owner: "John Doe",
  },
  {
    id: "3",
    fileName:
      "https://acme-corp.sharepoint.com/support-portal/documents/acme-feature-roadmap-2024Q4.docx",
    authorizedGroups: ["us_support_group@acmecorp.com"],
    accessCount: 2,
    owner: "John Doe",
  },
  {
    id: "1",
    fileName: "org: acme-corp.slack.com, channels: #support",
    authorizedGroups: ["us_support_group@acmecorp.com"],
    accessCount: 7,
    owner: "John Doe",
  },
  {
    id: "1",
    fileName:
      "https://drive.google.com/drive/u/2/folders/15CyFIWOPJOR5BxDID7G6tUisfHU1szrg/view/approvals/mortgage-loans.pdf",
    authorizedGroups: [
      "underwriter_group@acmecorp.com",
      "customer_care@acmecorp.com",
    ],
    accessCount: 7,
    owner: "John Doe",
  },
];

export const entityData = [
  "API Key",
  "JWT Token",
  "Client Access Token",
  "Client Secret",
  "Symmetric Key",
  "MRN",
  "PII - SSN",
  "PII - CC",
];

export const topicData = [
  "Medical Advice",
  "Harmful Advice",
  "Sexual Harassment",
  "Bias",
  "Board Meeting Notes",
  "Consulting Agreement",
  "Customer List",
  "Distribution/Partner Agreement",
  "Executive Severance Agreement",
  "Financial Report",
  "Loan And Security Agreement",
  "Merger Agreement",
  "NDA",
  "Patent Application Fillings",
  "Price List",
  "Settlement Agreement",
  "Employee Agreement",
  "Enterprise Agreement",
  "Product Roadmap",
  "Intellectual Property",
];

export const retrievalColorMapping = {
  "PII - SSN": "#BF87D6",
  "PII - CC": "#F2B485",
  "Intellectual Property": "#85BDED",
  Bias: "#A76171",
  "Sexual Harassment": "#ACCA9F",
};

export const anamolousUsersData = [
  {
    name: "John Doe",
    prompts: 54,
    attempts: 21,
    labels: ["Finance"],
    groups: ["Sales"],
  },
  {
    name: "Tom Platt",
    prompts: 21,
    attempts: 15,
    labels: ["Product Roadmap"],
    groups: ["Sales"],
  },
  {
    name: "Alex Smith",
    prompts: 12,
    attempts: 2,
    labels: ["PII - SSN"],
    groups: ["Sales"],
  },
  {
    name: "John Doe",
    prompts: 8,
    attempts: 10,
    labels: ["PII - CC"],
    groups: ["Sales"],
  },
];
