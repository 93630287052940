import {
  Checkbox,
  Collapse,
  Fade,
  IconButton,
  StepButton,
  Stepper,
  useTheme,
} from "@mui/material";
import Box from "../../components/common/Box";
import { Dialog, DialogActions } from "../../components/common/Dialog";
import Typography from "../../components/common/Typography";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Button from "../../components/common/Button";
import { Autocomplete } from "../../components/common/AutoComplete";
import { TextField } from "../../components/common/TextField";
import { useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { group } from "d3";
import SelectGroups from "./SelectGroups";
import { Step, StepContent, StepLabel } from "../../components/common/Stepper";
import SemanticGuardrailConfig from "./SemanticGuardrailConfig";
import TextButton from "../../components/common/TextButton";
import Chip from "../../components/common/Chip";
import StarIcon from "../../assets/img/star.svg";
import ExceptionConfig from "./ExceptionConfig";
import PolicyName from "./PolicyName";
import { TOASTER_TYPES, Toaster } from "../../utils/toaster";
import MapClassificationLabels from "./MapClassificationLabels";

const policyType = [
  "Identity violations in prompt session",
  "Restricted topics in prompt session ",
  "Sensitive identifiers in prompt session",
  "Fine-tuned on sensitive data",
  "Trained with data from publicly exposed datastore",
  "Fine-tuned with data violating residency",
];

const topicData = [
  //{ title: "All Topics" },
  { title: "Medical Advice", parent: "Healthcare" },
  { title: "Harmful Advice", parent: "AI Safety" },
  { title: "Sexual Harassment", parent: "AI Safety" },
  { title: "Bias", parent: "AI Safety" },
  { title: "Board Meeting Notes", parent: "Company Confidentials" },
  { title: "Consulting Agreement", parent: "Company Confidentials" },
  { title: "Customer List", parent: "Company Confidentials" },
  { title: "Distribution/Partner Agreement", parent: "Company Confidentials" },
  { title: "Executive Severance Agreement", parent: "Company Confidentials" },
  { title: "Financial Report", parent: "Company Confidentials" },
  { title: "Loan And Security Agreement", parent: "Company Confidentials" },
  { title: "Merger Agreement", parent: "Company Confidentials" },
  { title: "NDA", parent: "Company Confidentials" },
  { title: "Patent Application Fillings", parent: "Company Confidentials" },
  { title: "Price List", parent: "Company Confidentials" },
  { title: "Settlement Agreement", parent: "Company Confidentials" },
  { title: "Employee Agreement", parent: "Company Confidentials" },
  { title: "Enterprise Agreement", parent: "Company Confidentials" },
  { title: "Product Roadmap", parent: "Company Confidentials" },
  { title: "Intellectual Property", parent: "Company Confidentials" },
];

const entityData = [
  { title: "All Entities" },
  { title: "API Key" },
  { title: "JWT Token" },
  { title: "Client Access Token" },
  { title: "Client Secret" },
  { title: "Symmetric Key" },
  { title: "MRN" },
  { title: "PII - SSN" },
  { title: "PII - CC" },
];

const identityData = [
  { title: "Block retrieval on violation" },
  { title: "Alert on identity violation" },
];

const frameworkData = [
  { title: "All framework" },
  { title: "SupportApp-1" },
  { title: "HealthCareApp-1" },
  { title: "AcmeMortgageApp-1" },
  { title: "ProductInsights" },
];

export const AIAssistLabels = {
  topics: ["Harmful"],
  entities: ["Secrets"],
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PolicyMenu = () => {};

export const AddPolicyPage = ({ isOpen, handleClose, labelsList }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedGroups, setSelectedGroups] = useState<any>([]);
  const [addedAIAssist, setAddedAIAssist] = useState(false);
  const [selectedClassification, setSelectedClassification] = useState<any>(0);
  const [policyName, setPolicyName] = useState(
    "Identity Policy_3 Usergroups_AzureIdp_Oct_2024"
  );
  const [identityProvider, setIdentityProvider] = useState("");
  const [policyExceptionConfig, setPolicyExceptionConfig] =
    useState<any>(false);
  const [selectedLabels, setSelectedLabels] = useState<any>({
    topics: [],
    entities: [],
  });
  const [policyData, setPolicyData] = useState<any>({
    type: "",
    name: "",
    topic: [],
    entity: [],
    owner: "John Doe",
    framework: [],
    identity: [],
  });

  const theme = useTheme();
  const styles = {
    dialog: {
      display: "flex",
      flexDirection: "column",
      ".MuiPaper-root": {
        background: theme.palette.surface0.main,
      },
    },
    dialogHeader: {
      display: "flex",
      padding: 3,
      alignItems: "center",
    },
    backIcon: {
      color: "#9AA5C1",
      fontSize: theme.spacing(2.5),
      width: theme.spacing(2.5),
    },
    dialogBody: {
      display: "flex",
      flex: 1,
      borderBottom: `0.5px solid ${theme.palette.surface20.main}`,
      paddingX: 7.5,
      paddingY: 2,
      flexDirection: "column",
      gap: theme.spacing(4.5),
    },
    formBox: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      width: "40%",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    formHeading: {
      fontSize: "14px",
      opacity: 0.6,
    },
    aiChip: {
      backgroundColor: "transparent",
      color: addedAIAssist
        ? theme.palette.surface30.main
        : theme.palette.surface60.main,
      "& .MuiChip-avatar": {
        width: "10px",
        height: "10px",
      },
    },
  };

  const clearFields = () => {
    setPolicyExceptionConfig(false);
    setSelectedLabels({ topics: [], entities: [] });
    setAddedAIAssist(false);
    setSelectedGroups([]);
    setIdentityProvider("");
    handleClose();
    setActiveStep(0);
    setSelectedClassification("");
  };

  const handleSubmit = () => {
    //const data: any = localStorage.getItem("policyTableData");
    //const array = JSON.parse(data);
    //let mainData;
    //if (array?.length > 0) {
    //  mainData = [...array, { ...policyData, createdOn: new Date() }];
    //} else {
    //  mainData = [{ ...policyData, createdOn: new Date() }];
    //}
    //localStorage.setItem("policyTableData", JSON.stringify(mainData));
    //setPolicyData({
    //  type: "",
    //  name: "",
    //  topic: [],
    //  framework: [],
    //});
    const data: any = localStorage.getItem("policyConfig");
    const array = data ? JSON.parse(data) : [];

    array.push({
      policyName,
      type: "identity",
      config: {
        SemanticGuardrailConfig: selectedLabels,
        Exceptions: policyExceptionConfig,
        Groups: selectedGroups,
        ConfidentialityTags:
          selectedClassification > 0
            ? `${labelsList[labelsList?.length - 1]?.name} to ${
                labelsList[labelsList?.length - selectedClassification - 1]
                  ?.name
              }`
            : selectedClassification === -1
            ? "-"
            : labelsList[labelsList?.length - 1]?.name,
      },
      addedOn: new Date(),
      owner: "John Doe",
      violations: 0,
    });
    localStorage.setItem("policyConfig", JSON.stringify(array));

    clearFields();
    Toaster(TOASTER_TYPES.SUCCESS, `Policy ${policyName} added successfully.`);
  };

  const checkOption = (title) => {
    return policyData.topic.some((topicName) => topicName.title === title);
  };

  const checkGroup = (parentBundle) => {
    const bundleOptionsCount = topicData.filter(
      (topic) => topic.parent === parentBundle
    )?.length;
    const selectedGroupLength = policyData?.topic?.filter(
      (topicItem) => topicItem.parent === parentBundle
    )?.length;
    return bundleOptionsCount === selectedGroupLength;
  };

  const selectGroup = (bundle) => {
    const groupedTopics = topicData.filter((topic) => topic.parent === bundle);
    const selectedTopics = policyData.topic.filter(
      (selectedTopic) => selectedTopic.parent === bundle
    );
    if (selectedTopics.length > 0) {
      const newTopics = policyData.topic.filter(
        (topicItem) => topicItem.parent !== bundle
      );
      setPolicyData({ ...policyData, topic: newTopics });
    } else {
      const oldTopics = [...policyData.topic, ...groupedTopics];
      setPolicyData({ ...policyData, topic: oldTopics });
    }
  };

  const handleStep = (index) => {
    setActiveStep(index);
  };

  const steps = [
    {
      label: "Select Groups",
      component: (
        <SelectGroups
          selectedGroups={selectedGroups}
          setSelectedGroups={setSelectedGroups}
          identityProvider={identityProvider}
          setIdentityProvider={setIdentityProvider}
        />
      ),
    },
    {
      label: "Semantic Guardrail Configuration",
      component: (
        <SemanticGuardrailConfig
          selectedLabels={selectedLabels}
          setSelectedLabels={setSelectedLabels}
          isAIAssist={addedAIAssist}
        />
      ),
    },
    {
      label: "Map Confidentiality Tags",
      component: (
        <MapClassificationLabels
          selectedClassification={selectedClassification}
          setSelectedClassification={setSelectedClassification}
          labelsList={labelsList}
        />
      ),
    },
    {
      label: "Exception Configuration",
      component: (
        <ExceptionConfig
          setPolicyExceptionConfig={setPolicyExceptionConfig}
          policyExceptionConfig={policyExceptionConfig}
        />
      ),
    },
    {
      label: "Policy Name",
      component: (
        <PolicyName policyName={policyName} setPolicyName={setPolicyName} />
      ),
    },
  ];

  return (
    <Dialog fullScreen open={isOpen} sx={styles.dialog}>
      <Box sx={styles.dialogHeader}>
        <IconButton
          onClick={() => {
            clearFields();
            handleClose();
          }}
        >
          <ArrowBackIosIcon sx={styles.backIcon} />
        </IconButton>
        <Typography variant="subtitle1" color={theme.palette.surface50.main}>
          Add Policy
        </Typography>
      </Box>
      <Box sx={styles.dialogBody}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          sx={{
            width: "80%",
          }}
        >
          {steps.map((step, index) => (
            <Step key={step.label} onClick={() => handleStep(index)}>
              <StepLabel
                sx={{
                  padding: 0,
                  display: "flex",
                  "& .MuiStepLabel-labelContainer": {
                    padding: theme.spacing(2),
                    backgroundColor: theme.palette.surface10.main,
                  },
                }}
              >
                {step.label === "Semantic Guardrail Configuration" &&
                activeStep === 1 ? (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: theme.spacing(2),
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: theme.spacing(1),
                        }}
                      >
                        {step.label}
                        <Typography
                          variant="body2"
                          color={theme.palette.surface50.main}
                        >
                          Choose Topics and Entities to restrict for selected
                          users
                        </Typography>
                      </Box>
                      <Button
                        disabled={addedAIAssist}
                        variant="outlined"
                        sx={{ marginLeft: "auto" }}
                        onClick={() => {
                          setSelectedLabels({
                            entities: [
                              ...selectedLabels.entities,
                              ...AIAssistLabels?.entities?.filter(
                                (entity) =>
                                  !selectedLabels.entities.includes(entity)
                              ),
                            ],
                            topics: [
                              ...selectedLabels.topics,
                              ...AIAssistLabels?.topics?.filter(
                                (topic) =>
                                  !selectedLabels.topics.includes(topic)
                              ),
                            ],
                          });
                          setAddedAIAssist(true);
                        }}
                      >
                        <Chip
                          sx={styles.aiChip}
                          label="AI Assist"
                          avatar={<img src={StarIcon} />}
                        />
                      </Button>
                    </Box>
                    {addedAIAssist ? (
                      <Box
                        sx={{
                          backgroundColor: "rgba(33, 144, 247, 0.2)",
                          borderRadius: "4px",
                          padding: theme.spacing(2),
                          display: "flex",
                          gap: theme.spacing(4),
                          alignItems: "center",
                        }}
                      >
                        <Chip
                          sx={styles.aiChip}
                          label="AI Assist"
                          avatar={<img src={StarIcon} />}
                        />
                        <Typography
                          variant="caption"
                          color={theme.palette.surface100.main}
                        >
                          Based on the groups you selected, here are the
                          recommended restricted entites and topics.
                        </Typography>
                        <TextButton
                          onClick={() => {
                            setSelectedLabels({
                              entities: selectedLabels.entities.filter(
                                (entity) =>
                                  !AIAssistLabels?.entities?.includes(entity)
                              ),
                              topics: selectedLabels.topics.filter(
                                (topic) =>
                                  !AIAssistLabels?.topics?.includes(topic)
                              ),
                            });
                            setAddedAIAssist(false);
                          }}
                        >
                          Undo
                        </TextButton>
                      </Box>
                    ) : null}
                  </Box>
                ) : (
                  step.label
                )}
              </StepLabel>
              <StepContent
                TransitionComponent={Collapse}
                transitionDuration={2}
                sx={{
                  paddingRight: 0,
                  "& .MuiCollapse-root": {
                    backgroundColor: theme.palette.surface10.main,
                  },
                }}
              >
                {step.component}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            clearFields();
            setActiveStep(0);
          }}
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Add Policy
        </Button>
      </DialogActions>
    </Dialog>
  );
};
