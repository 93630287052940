import { useTheme } from "@mui/material";
import Box from "./Box";
import Typography from "./Typography";

export const SolidChip = ({ text, color }) => {
  const theme = useTheme();
  const styles = {
    chip: {
      width: "fit-content",
      padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
      borderRadius: "4px",
      backgroundColor: color,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
  return (
    <Box sx={styles.chip}>
      <Typography variant="tooltip" color={theme.palette.surface10.main}>
        {text}
      </Typography>
    </Box>
  );
};

export default SolidChip;
