import { useState } from "react";
import CardWithAction from "../common/CardWithAction";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../common/Table";
import { documents } from "../../mock-data/documents";
import Box from "../common/Box";
import { useTheme } from "@mui/material";
import { getIcon, getStringFromArray } from "../../utils/commonUtils";
import Typography from "../common/Typography";
import HarmfulBadge from "../common/HarmfulBadge";
import { TagColorMapping } from "../../mock-data/tagColorMapping";
import { useHistory } from "react-router-dom";
import Tooltip from "../common/Tooltip";
import SolidChip from "../common/SolidChip";

const DocumentsListing = () => {
  const [docs, setDocs] = useState<any>(documents);
  const theme = useTheme();
  const history = useHistory();
  const styles = {
    docName: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "center",
    },
    imgBg: {
      display: "flex",
      width: "32px",
      height: "32px",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#2E3351",
      borderRadius: "50%",
    },
    name: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    snippetCol: {
      gap: theme.spacing(0.5),
    },
    snippet: {
      display: "flex",
      gap: theme.spacing(1),
      alignItems: "baseline",
    },
    groups: {
      alignItems: "center",
    },
    manrope: {
      fontFamily: "Manrope",
    },
    snippetCount: {
      fontSize: "20px",
      color: theme.palette.surface90.main,
      fontWeight: "500",
    },
    groupCount: {
      fontSize: "20px",
      color: theme.palette.surface60.main,
    },
  };

  const getBadge = (tag) => {
    return <HarmfulBadge color={TagColorMapping[tag]} />;
  };

  const gotoDocument = (name) => {
    history.push("/documents/" + name);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Snippets with Impact</TableCell>
            <TableCell>Groups with Access</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map((doc) => {
            const labels = doc?.sensitiveLabels?.map((label) => label.name);
            // sum of count in sensitiveLabels array
            const snippetCount = doc?.sensitiveLabels?.reduce(
              (acc, label) => label?.count + acc,
              0
            );
            return (
              <TableRow
                sx={{ cursor: "pointer" }}
                onClick={() => gotoDocument(doc?.name)}
              >
                <TableCell>
                  <Box sx={styles.docName}>
                    <Box sx={styles.imgBg}>
                      <img
                        src={getIcon(doc?.source)}
                        alt="source"
                        height={"20px"}
                      />
                    </Box>
                    <Box sx={styles.name}>
                      <Typography
                        variant="body2"
                        color={theme.palette.surface60.main}
                      >
                        {doc?.name}
                      </Typography>
                      <Typography
                        variant="tooltip"
                        color={theme.palette.surface50.main}
                      >
                        {doc?.size}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ ...styles.name, ...styles.snippetCol }}>
                    <Box sx={styles.snippet}>
                      <Typography
                        sx={{ ...styles.manrope, ...styles.snippetCount }}
                      >
                        {doc?.snippetImpact?.value || snippetCount}
                      </Typography>
                      <Typography
                        variant="body2"
                        color={theme.palette.surface60.main}
                      >
                        {doc?.snippetImpact?.tag}
                      </Typography>
                      {doc?.snippetImpact?.tag
                        ? getBadge(doc?.snippetImpact?.tag)
                        : ""}
                    </Box>
                    <Tooltip
                      title={getStringFromArray(labels, labels?.length)}
                      children={
                        <div>
                          <Typography
                            variant="caption"
                            color={theme.palette.surface50.main}
                          >
                            {getStringFromArray(labels)}
                          </Typography>
                        </div>
                      }
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ ...styles.name, ...styles.snippetCol }}>
                    <Box sx={{ ...styles.snippet, ...styles.groups }}>
                      <Typography
                        sx={{ ...styles.groupCount, ...styles.manrope }}
                      >
                        {doc?.authorizedGroups?.length}
                      </Typography>
                      {doc?.overprovisioned ? (
                        <SolidChip
                          text={`${doc?.overprovisioned} Over-Provisioned`}
                          color="#D9946D"
                        />
                      ) : (
                        ""
                      )}
                    </Box>
                    <Tooltip
                      title={getStringFromArray(
                        doc?.authorizedGroups,
                        doc?.authorizedGroups?.length
                      )}
                      children={
                        <div>
                          <Typography
                            variant="caption"
                            color={theme.palette.surface50.main}
                          >
                            {getStringFromArray(doc?.authorizedGroups)}
                          </Typography>
                        </div>
                      }
                    />
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const DocumentsCard = () => {
  const history = useHistory();
  const gotoAllDocuments = () => {
    history.push("/documents");
  };

  return (
    <Box
      sx={{
        width: "70%",
      }}
      className="dashboard-rows"
      id="row-1"
    >
      <CardWithAction
        btnAction={gotoAllDocuments}
        btnTitle="View All"
        subText=""
        title="Most Impacted Documents"
        children={<DocumentsListing />}
      />
    </Box>
  );
};

export default DocumentsCard;
