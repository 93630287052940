import { useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import { Tab, Tabs } from "../../components/common/Tabs";
import CustomTab from "../../components/common/CustomTabs";
import { useEffect, useMemo, useState } from "react";
import AIInsightsTip from "../users/AIInsightsTip";
import Typography from "../../components/common/Typography";
import { Select } from "../../components/common/Select";
import LabelWithSnippetCount from "./LabelWithSnippetCount";
import Snippet from "./Snippet";
import { frameworkPromptSummaryData } from "../../mock-data/frameworkPromptMessagesData";
import NoData from "../../components/common/NoData";
import CreateTicketModal from "../../components/modal/create-ticket-modal/CreateTicketModal";
import {
  capitalizeFirstLetter,
  getStringFromArray,
} from "../../utils/commonUtils";

const DocumentRetrievals = ({ document }) => {
  const [openCreateTicket, setOpenCreateTicket] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [selectedLabel, setSelectedLabel] = useState("");
  const [snippets, setSnippets] = useState<any>([]);
  const [labels, setLabels] = useState<any>([]);
  const [allPrompts, setAllPrompts] = useState<any>([]);
  const theme = useTheme();

  const styles = {
    pageBody: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    snippetsCard: {},
    noBottomBorder: {
      "&.MuiInputBase-root": {
        fontSize: theme.typography.caption,
        color: theme.palette.surface70.main,
        fontWeight: theme.typography.fontWeightMedium,
        marginTop: 0,
      },
    },
    promptSelector: {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #292D40",
      padding: theme.spacing(2),
    },
    filters: {
      display: "flex",
      gap: theme.spacing(2),
      alignItems: "center",
    },
    font13: {
      fontSize: "13px",
    },
    panel: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.surface10.main,
      borderRadius: "4px",
      height: "500px",
      overflow: "hidden",
    },
    cardContent: {
      display: "grid",
      gridTemplateColumns: "30% 70%",
      flexGrow: "1",
      overflow: "hidden",
    },
    gridTitle: {
      padding: theme.spacing(2),
      borderBottom: "1px solid #292D40",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      flexGrow: "1",
      overflow: "hidden",
    },
    column1: {
      borderRight: "1px solid #292D40",
      //  overflow: "hidden",
    },
    column2: {
      //  overflow: "hidden",
    },
    snippetList: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
      overflowY: "auto",
      height: "100%",
    },
  };

  const selectDays = [
    {
      label: "All Days",
      value: "all",
    },
  ];

  const selectApps = [
    { label: "All Applications (2)", value: "all" },
    {
      label: "SupportApp-1",
      value: "SupportApp-1",
    },
    {
      label: "AcmeMortgageApp-1",
      value: "AcmeMortgageApp-1",
    },
  ];

  const selectDataTypes = [{ label: "All Data Types (3)", value: "all" }];

  const tabs = useMemo(() => {
    return [
      {
        label: "Snippets with Harmful Data",
        color: "#B9605B",
        value: "harmful",
        data: allPrompts.filter((prompt) => prompt.docType === "harmful"),
        outOf: 0,
      },
      {
        label: "Snippets with Sensitive Content",
        value: "snippets_sensitive_content",
        data: allPrompts.filter(
          (prompt) => prompt?.docType === "snippets_sensitive_content"
        ),
        outOf: allPrompts?.length,
      },
    ];
  }, [allPrompts]);

  const getTicketBody = () => {
    if (document) {
      return `Ticket to clean the document source and re-ingest.\nDocument: ${
        document?.name
      }\nSize: ${document?.size}\n\nSource: ${capitalizeFirstLetter(
        document?.type
      )}\nPath: ${document?.path}\n\nVectorDB: ${capitalizeFirstLetter(
        document?.database?.name
      )}\nLast Updated On: ${
        document?.database?.updatedAt
      }\n\nGroups with Access: ${document?.authorizedGroups?.join(
        ","
      )}\n\nHarmful Labels: \n${document?.harmfulLabels?.map(
        (label) => ` ${label?.name}: ${label?.count}`
      )}\n\nSensitive Labels: \n${document?.sensitiveLabels?.map(
        (label) => ` ${label?.name}: ${label?.count}`
      )}\n\nSnippets:\n${allPrompts
        ?.filter(
          (prompt) =>
            prompt?.docType === "snippets_sensitive_content" &&
            prompt?.label?.includes("AWS")
        )
        ?.map(
          (snippet) =>
            `Snippet: ${snippet.finalContext}\nRetrieved On: ${snippet.retrievedOn}\nRetrieved By: ${snippet.retrievedBy}\n\n`
        )}`;
    }
    return "";
  };

  useEffect(() => {
    let idCounter = 1;
    const allPrompts = frameworkPromptSummaryData.flatMap((app) =>
      (app?.snippetData || app?.promptSummaryData).flatMap((dataArray) =>
        dataArray.flatMap((obj, index) =>
          obj.messages
            ?.filter((prompt) => prompt.sourceFile.includes(document?.name))
            .map((message) => ({
              ...message,
              id: idCounter++,
              label: obj.sensitiveLabel,
              app: app.name,
            }))
        )
      )
    );

    setSelectedTab(tabs[0].value);
    setAllPrompts(allPrompts);
  }, [document?.name]);

  useEffect(() => {
    if (selectedTab) {
      const snippetList = allPrompts.filter(
        (prompts) => prompts?.docType === selectedTab
      );
      const groupedItems: any = Object.values(
        snippetList.reduce((acc, item) => {
          const { label } = item;
          if (!acc[label]) {
            acc[label] = { label, value: [item] };
          } else acc[label]?.value?.push(item);
          return acc;
        }, {})
      );
      setLabels(groupedItems);
      setSelectedLabel(groupedItems[0]?.label);
    }
  }, [selectedTab, allPrompts]);

  useEffect(() => {
    setSnippets(labels.find((label) => label.label === selectedLabel)?.value);
  }, [selectedLabel]);

  return (
    <Box sx={styles.pageBody}>
      <Tabs
        value={selectedTab}
        onChange={(event, newValue) => setSelectedTab(newValue)}
        sx={{
          display: "flex",
          gap: theme.spacing(2),
          "&.MuiTab-root": {
            paddingBottom: "0",
          },
        }}
      >
        {tabs.map((tab) => (
          <Tab
            sx={{
              "&.MuiTab-root": {
                paddingBottom: 0,
              },
            }}
            label={
              <CustomTab
                {...tab}
                count={tab?.data?.length || 0}
                selectedValue={selectedTab}
              />
            }
            value={tab.value}
          />
        ))}
      </Tabs>
      <Box sx={styles.snippetsCard}>
        <Box sx={styles.panel}>
          <Box sx={styles.promptSelector}>
            <AIInsightsTip
              show={
                selectedTab === "snippets_sensitive_content" &&
                document?.aiInsight
              }
              text={document?.aiInsight}
              onCreateTicket={() => setOpenCreateTicket(true)}
            />
            <Box sx={styles.filters}>
              <Typography
                color={theme.palette.surface30.main}
                sx={styles.font13}
              >
                Show Prompts for:
              </Typography>
              <Select
                options={selectDays}
                value="all"
                sx={styles.noBottomBorder}
              />
              <Select
                options={selectApps}
                value="all"
                sx={styles.noBottomBorder}
              />
              <Select
                options={selectDataTypes}
                value="all"
                sx={styles.noBottomBorder}
              />
            </Box>
          </Box>
          <Box sx={styles.cardContent}>
            <Box sx={{ ...styles.column, ...styles.column1 }}>
              <Box sx={styles.gridTitle}>
                <Typography
                  color={theme.palette.surface70.main}
                  sx={styles.font13}
                >
                  Labels
                </Typography>
              </Box>
              <LabelWithSnippetCount
                onChange={(label) => setSelectedLabel(label)}
                selected={selectedLabel}
                labels={labels}
              />
            </Box>
            <Box sx={{ ...styles.column, ...styles.column2 }}>
              <Box sx={styles.gridTitle}>
                <Typography
                  color={theme.palette.surface70.main}
                  sx={styles.font13}
                >
                  Snippets
                </Typography>
              </Box>
              <Box sx={styles.snippetList}>
                {snippets?.length ? (
                  snippets.map((snippet) => (
                    <Snippet
                      retrievedOn={snippet.retrievedOn}
                      snippet={snippet.finalContext}
                    />
                  ))
                ) : (
                  <NoData customText="No Snippets" />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <CreateTicketModal
        open={openCreateTicket}
        onClose={() => setOpenCreateTicket(false)}
        title={
          "Multiple AWS Access Key has been ingested from Slack Channel: #support."
        }
        body={getTicketBody()}
      />
    </Box>
  );
};

export default DocumentRetrievals;
