import { useTheme } from "@mui/material";
import Box from "../../components/common/Box";
import { Tab, Tabs } from "../../components/common/Tabs";
import { useEffect, useMemo, useState } from "react";
import CustomTab from "../../components/common/CustomTabs";
import { frameworkPromptSummaryData } from "../../mock-data/frameworkPromptMessagesData";
import UserPromptsCard from "./UserPromptsCard";
import PromptDetailsCard from "./PromptDetailsCard";
import AIInsightsTip from "./AIInsightsTip";
import Typography from "../../components/common/Typography";
import { MenuItem, Select } from "../../components/common/Select";
import NoData from "../../components/common/NoData";
import CreateTicketModal from "../../components/modal/create-ticket-modal/CreateTicketModal";

const UserRetreivals = ({ user }) => {
  const [selectedTab, setSelectedTab] = useState("anomalous");
  const [userPrompts, setUserPrompts] = useState<any>(null);
  const [selectedPrompt, setSelectedPrompt] = useState<any>(null);
  const [allPrompts, setAllPrompts] = useState<any>([]);
  const [openCreateTicket, setOpenCreateTicket] = useState(false);

  const theme = useTheme();

  const styles = {
    pageBody: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    promptsPanel: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.surface10.main,
      borderRadius: "4px",
      //  paddingY: theme.spacing(2),
      height: "500px",
      overflow: "hidden",
    },
    promptSelector: {
      display: "flex",
      justifyContent: "space-between",
      gap: theme.spacing(2),
      borderBottom: "1px solid #292D40",
      padding: theme.spacing(2),
    },
    prompts: {
      display: "grid",
      gridTemplateColumns: "30% 70%",
      flexGrow: "1",
      overflow: "hidden",
    },
    promptList: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(0.5),
      height: "100%",
      overflowY: "auto",
    },
    filters: {
      display: "flex",
      gap: theme.spacing(2),
      alignItems: "center",
    },
    font13: {
      fontSize: "13px",
    },
    noBottomBorder: {
      "&.MuiInputBase-root": {
        fontSize: theme.typography.caption,
        color: theme.palette.surface70.main,
        fontWeight: theme.typography.fontWeightMedium,
        marginTop: 0,
      },
    },
  };

  const tabs = useMemo(() => {
    return [
      {
        label: "Anomalous Prompts",
        color: "#B9605B",
        value: "anomalous",
        outOf: 0,
        data: allPrompts?.filter((prompt) => prompt.type === "anomalous"),
      },
      {
        label: "Sensitive Data in Responses ",
        color: "#F88D50",
        value: "sensitive_response",
        outOf: 0,
        data: allPrompts?.filter(
          (prompt) => prompt.type === "sensitive_response"
        ),
      },
      {
        label: "Sensitive Data in Prompts",
        color: "#6750A3",
        value: "sensitive_prompt",
        outOf: 0,
        data: allPrompts?.filter(
          (prompt) => prompt.type === "sensitive_prompt"
        ),
      },
      {
        label: "Total Prompts",
        value: "all",
        outOf: 0,
        data: allPrompts,
      },
    ];
  }, [allPrompts]);

  useEffect(() => {
    let idCounter = 1;
    const allPrompts = frameworkPromptSummaryData.flatMap((app) =>
      app.promptSummaryData.flatMap((dataArray) =>
        dataArray.flatMap((obj, index) =>
          obj.messages
            ?.filter((message) => message?.retrievedBy === user?.name)
            ?.map((message) => ({
              ...message,
              id: idCounter++,
              label: obj.sensitiveLabel,
              app: app.name,
            }))
        )
      )
    );
    setAllPrompts(allPrompts);
  }, [user?.name]);

  useEffect(() => {
    if (selectedTab && allPrompts) {
      const filteredPrompts =
        selectedTab === "all"
          ? allPrompts
          : allPrompts?.filter((prompt) => prompt.type === selectedTab);
      setUserPrompts(filteredPrompts);
      setSelectedPrompt(filteredPrompts[0]);
    }
  }, [allPrompts, selectedTab]);

  const selectDays = [
    {
      label: "All Days",
      value: "all",
    },
  ];

  const selectApps = [
    { label: "All Applications (2)", value: "all" },
    {
      label: "SupportApp-1",
      value: "SupportApp-1",
    },
    {
      label: "AcmeMortgageApp-1",
      value: "AcmeMortgageApp-1",
    },
  ];

  const selectDataTypes = [{ label: "All Data Types (3)", value: "all" }];

  const getTicketBody = () => {
    if (selectedPrompt) {
      return `Ticket to restrict Alex Smith's access:\n\n Name: ${
        user?.name
      }\nGroup: ${user?.designation}\nGroups: ${user?.groups?.join(
        ","
      )}\nApplications: ${user?.apps?.join(
        ","
      )}\nRetrievals: ${user?.retrievals?.map(
        (retrieval) => " " + retrieval?.label + " : " + retrieval?.value
      )}\n\nPrompts:\n ${userPrompts?.map(
        (prompt) =>
          `\nPrompt: ${prompt?.prompt}\nLabel: ${prompt?.label}\nRespone: ${prompt?.response}\nRetrieved On: ${prompt?.retrievedOn}\n`
      )}`;
    }
    return "";
  };

  return (
    <Box sx={styles.pageBody}>
      <Tabs
        value={selectedTab}
        onChange={(event, newValue) => setSelectedTab(newValue)}
        sx={{
          display: "flex",
          gap: theme.spacing(2),
          "&.MuiTab-root": {
            paddingBottom: "0",
          },
        }}
      >
        {tabs.map((tab) => (
          <Tab
            sx={{
              "&.MuiTab-root": {
                paddingBottom: 0,
              },
            }}
            label={
              <CustomTab
                {...tab}
                count={tab?.data?.length || 0}
                selectedValue={selectedTab}
              />
            }
            value={tab.value}
          />
        ))}
      </Tabs>

      <Box sx={styles.promptsPanel}>
        <Box sx={styles.promptSelector}>
          <AIInsightsTip
            show={selectedTab === "anomalous" && user?.aiInsight}
            text={user?.aiInsight}
            onCreateTicket={() => setOpenCreateTicket(true)}
          />
          <Box sx={styles.filters}>
            <Typography color={theme.palette.surface30.main} sx={styles.font13}>
              Show Prompts for:
            </Typography>
            <Select
              options={selectDays}
              value="all"
              sx={styles.noBottomBorder}
            />
            <Select
              options={selectApps}
              value="all"
              sx={styles.noBottomBorder}
            />
            <Select
              options={selectDataTypes}
              value="all"
              sx={styles.noBottomBorder}
            />
          </Box>
        </Box>
        {userPrompts?.length ? (
          <Box sx={styles.prompts}>
            <Box sx={styles.promptList}>
              {userPrompts?.length &&
                userPrompts?.map((prompt) => (
                  <UserPromptsCard
                    timestamp={prompt.retrievedOn}
                    selected={
                      selectedPrompt ? selectedPrompt?.id === prompt.id : false
                    }
                    prompt={prompt.prompt}
                    color={tabs.find((tab) => tab.value === selectedTab)?.color}
                    onSelect={() => setSelectedPrompt(prompt)}
                    label={prompt.label}
                  />
                ))}
            </Box>
            <PromptDetailsCard
              promptDetails={selectedPrompt}
              onCreateTicket={() => setOpenCreateTicket(true)}
            />
          </Box>
        ) : (
          <NoData customText="No Prompts Found" />
        )}
      </Box>
      <CreateTicketModal
        open={openCreateTicket}
        onClose={() => setOpenCreateTicket(false)}
        title={
          "Alex Smith has been accessing higher amounts of AWS Access Key data recently."
        }
        body={getTicketBody()}
      />
    </Box>
  );
};

export default UserRetreivals;
